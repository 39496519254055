<template>
<div class="col-md-9" v-if="listNews">
    <h2 class="title-topic">Danh sách câu hỏi</h2>
    <div v-for="news in listNews" v-bind:key="news.id" class="item-box">
        <detail-news :news="news"></detail-news>
        <list-comment :post_id="news._id" :postid="news.id" :user_id="news.created_id"></list-comment>
    </div>
    <infinite-loading @distance="1" @infinite="handleLoadMore"></infinite-loading>
</div>
</template>
<script>
import {
    mapGetters,
    mapActions
} from "vuex";
import detailNews from "../news/detail-new.vue"
import listComment from "../comments/list-comment.vue"
import InfiniteLoading from "vue-infinite-loading";
import axios from "axios";
import RepositoryFactory from "../../service/RepositoryFactory";
const NewsRepository = RepositoryFactory.NewsRepository;
export default {
    name: "News",
    data() {
        return {
            listNews: null,
            listCate: null,
            page: 1
        };
    },
    components: {
        InfiniteLoading,
        listComment,
        detailNews
    },
    metaInfo: {
        title: "Tin tức pháp luật",
    },
    created: function () {
        this.listNews = this.News.data;
        this.listCate = this.CateParents;
        this.fetch(5, this.page);
    },
    computed: {
        isLoggedIn: function () {
            return this.$store.getters.isAuthenticated;
        },
        ...mapGetters({
            News: "StateNews",
            CateParents: "StateCateParents",
            User: "StateUser",
        }),
    },
    methods: {
        ...mapActions(["GetNews", "GetCategoryParents"]),
        async fetch($page) {
            const {
                data
            } = await NewsRepository.get(5, $page);
            console.log(data);
            return data;
        },
        handleLoadMore($state) {
            setTimeout(() => {
                this.page++;
                let url = "/api/v1/law/news?perPage=5&page=" + this.page;
                console.log(this.fetch(this.page));
                axios
                    .get(url)
                    .then((res) => {
                        return res.data;
                    })
                    .then((res) => {
                        if (res.data.length > 0) {
                            res.data.forEach((value) => {
                                this.listNews.push(value);
                            });
                            $state.loaded();
                        } else {
                            $state.complete();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 200);
        }
    },
    mounted: function () {
        this.GetNews();
        this.GetCategoryParents();
    },
    watch: {
        name(newName) {
            localStorage.name = newName;
        },
    },
};
</script>