<template>
<div class="main-landingpageLAW">
    <div class="head-slider">
        <div class="banner-img">
            <div class="pc">
                <img src="../assets/images/BANNER 1.svg" alt="">
                <svg class="line1" xmlns="http://www.w3.org/2000/svg" width="218" height="43" viewBox="0 0 218 43" fill="none">
                    <path class="tenlualine1" d="M1.5 0.5V41.5H216.5V10.5" stroke="white" stroke-width="1.5" stroke-dasharray="6 6" />
                </svg>
                <svg class="line2" xmlns="http://www.w3.org/2000/svg" width="88" height="53" viewBox="0 0 88 53" fill="none">
                    <path class="tenlualine1" d="M0 1H86.5V53" stroke="white" stroke-width="1.5" stroke-dasharray="6 6" />
                </svg>
                <svg class="line3" xmlns="http://www.w3.org/2000/svg" width="143" height="33" viewBox="0 0 143 33" fill="none">
                    <path class="tenlualine1" d="M0 32H142V0" stroke="white" stroke-width="1.5" stroke-dasharray="6 6" />
                </svg>
                <svg class="line4" xmlns="http://www.w3.org/2000/svg" width="74" height="49" viewBox="0 0 74 49" fill="none">
                    <path class="tenlualine1" d="M73 0V47.5H0" stroke="white" stroke-width="1.5" stroke-dasharray="6 6" />
                </svg>
                <svg class="line5" xmlns="http://www.w3.org/2000/svg" width="3" height="77" viewBox="0 0 3 77" fill="none">
                    <path class="tenlualine1" d="M1.5 0V76.5" stroke="white" stroke-width="1.5" stroke-dasharray="6 6" />
                </svg>
                <svg class="line6" xmlns="http://www.w3.org/2000/svg" width="3" height="89" viewBox="0 0 3 89" fill="none">
                    <path class="tenlualine1" d="M1.5 0V88.5" stroke="white" stroke-width="1.5" stroke-dasharray="6 6" />
                </svg>
                <div class="button-action">
                    <a href="#" class="button btn-contactus">
                        <i class="icon-phone"></i>Liên hệ chúng tôi
                    </a>
                    <a href="#" class="button btn-readmore">
                        <i class="ti-arrow-right"></i>Hiểu thêm về chúng tôi
                    </a>
                </div>
            </div>
            <img class="mb" src="../assets/images/BANNER_mb.svg" alt="">
            <div class="button-action-mb">
                <a href="#" class="button btn-contactus">
                    <i class="icon-phone"></i>Liên hệ chúng tôi
                </a>
                <a href="#" class="button btn-readmore">
                    <i class="ti-arrow-right"></i>Hiểu thêm về chúng tôi
                </a>
            </div>
        </div>
    </div>
    <!-- Thiết kế website -->
    <section class="design-web wow fadeInUp ">
        <div class="bg_section">
            <div class="container">
                <div class="title-section">
                    <h2 class="wow fadeInUp ">Thiết kế và quản trị nội dung website</h2>
                    <p>Cung cấp các gói dịch vụ bao gồm thiết kế từ đơn giản đến thiết kế độc quyền, chuyên nghiệp, hỗ trợ chỉnh sửa tức thời.
                        Và các dịch vụ quản trị nội dung website chuyên nghiệp, tối ưu...</p>
                </div>
                <div class="row ">
                    <div class="col-md-4">
                        <div class="item-price1 hvr-grow">
                            <div class="title-price">
                                <img class="icon-price" src="../assets/images/coban.svg">
                                <h3><span>Gói cơ bản</span></h3>
                                <p class="prices">3.000.000đ/ tháng </p>

                            </div>
                            <img class="icon-light" src="../assets/images/light.svg">
                            <div class="content-price">
                                <div class="border-box">
                                    <p class="detail-prices">Thiết kế website</p>
                                    <ul>
                                        <li>
                                            <img class="tick" src="../assets/images/tick1.svg">
                                            <span>Giao diện đơn giản</span>
                                        </li>
                                        <li>
                                            <img class="tick" src="../assets/images/tick1.svg">
                                            <span>Tặng tên miền</span>
                                        </li>
                                        <li>
                                            <img class="tick" src="../assets/images/tick1.svg">
                                            <span>Hỗ trợ chỉnh sửa</span>
                                        </li>
                                    </ul>

                                </div>
                                <img class="icon-light" src="../assets/images/light.svg">
                                <div class="border-box">
                                    <p class="detail-prices">Quản trị nội dung</p>
                                    <ul>
                                        <li>
                                            <img class="tick" src="../assets/images/tick1.svg">
                                            <span>10 bài viết chuẩn SEO/tháng</span>
                                        </li>
                                        <li>
                                            <img class="tick" src="../assets/images/tick1.svg">
                                            <span>Cập nhật thay đổi hằng ngày</span>
                                        </li>

                                    </ul>
                                    <div class="registers " data-toggle="modal" data-target="#modal-dang-ky-ngay">
                                        <a class="button btn-register" data-toggle="modal" data-target="#modal-dang-ky-ngay">Đăng ký ngay</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="item-price2 hvr-grow">
                            <div class="title-price">
                                <img class="icon-price" src="../assets/images/chuyennghiep.svg">
                                <h3><span>Gói CHUYÊN NGHIỆP</span></h3>
                                <p class="prices">5.000.000đ/tháng</p>

                            </div>
                            <img class="icon-light" src="../assets/images/light.svg">
                            <div class="content-price">
                                <div class="border-box">
                                    <p class="detail-prices">Thiết kế website</p>
                                    <ul>
                                        <li>
                                            <img class="tick" src="../assets/images/tick1.svg">
                                            <span>Giao diện chuyên nghiệp</span>
                                        </li>
                                        <li>
                                            <img class="tick" src="../assets/images/tick1.svg">
                                            <span>Tặng tên miền</span>
                                        </li>
                                        <li>
                                            <img class="tick" src="../assets/images/tick1.svg">
                                            <span>Hỗ trợ chỉnh sửa</span>
                                        </li>
                                    </ul>

                                </div>
                                <img class="icon-light" src="../assets/images/light.svg">
                                <div class="border-box">
                                    <p class="detail-prices">Quản trị nội dung</p>
                                    <ul>
                                        <li>
                                            <img class="tick" src="../assets/images/tick1.svg">
                                            <span>10 bài viết chuẩn SEO/tháng</span>
                                        </li>
                                        <li>
                                            <img class="tick" src="../assets/images/tick1.svg">
                                            <span>Cập nhật thay đổi hằng ngày</span>
                                        </li>
                                        <li>
                                            <img class="tick" src="../assets/images/tick1.svg">
                                            <span>SEO website</span>
                                        </li>

                                    </ul>
                                    <div class="registers" data-toggle="modal" data-target="#modal-dang-ky-ngay">
                                        <a class="button btn-register" data-toggle="modal" data-target="#modal-dang-ky-ngay"> Đăng ký ngay</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="item-price3 hvr-grow">
                            <div class="title-price">
                                <img class="icon-price" src="../assets/images/caocap.svg">
                                <h3><span>Gói cao cấp</span></h3>
                                <p class="prices">8.000.000đ/ tháng </p>

                            </div>
                            <img class="icon-light" src="../assets/images/light.svg">
                            <div class="content-price">
                                <div class="border-box">
                                    <p class="detail-prices">Thiết kế website</p>
                                    <ul>
                                        <li>
                                            <img class="tick" src="../assets/images/tick1.svg">
                                            <span>Thiết kế độc quyền</span>
                                        </li>
                                        <li>
                                            <img class="tick" src="../assets/images/tick1.svg">
                                            <span>Tặng tên miền</span>
                                        </li>
                                        <li>
                                            <img class="tick" src="../assets/images/tick1.svg">
                                            <span>Hỗ trợ chỉnh sửa</span>
                                        </li>
                                    </ul>

                                </div>
                                <img class="icon-light" src="../assets/images/light.svg">
                                <div class="border-box">
                                    <p class="detail-prices">Quản trị nội dung</p>
                                    <ul>
                                        <li>
                                            <img class="tick" src="../assets/images/tick1.svg">
                                            <span>20 bài viết chuẩn SEO/tháng</span>
                                        </li>
                                        <li>
                                            <img class="tick" src="../assets/images/tick1.svg">
                                            <span>SEO website</span>
                                        </li>
                                        <li>
                                            <img class="tick" src="../assets/images/tick1.svg">
                                            <span>Facebook Ads</span>
                                        </li>
                                        <li>
                                            <img class="tick" src="../assets/images/tick1.svg">
                                            <span>Google Ads</span>
                                        </li>

                                    </ul>
                                    <div class="registers">
                                        <a class="button btn-register" data-toggle="modal" data-target="#modal-dang-ky-ngay"> Đăng ký ngay</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Thiết kế website -->
    <!-- Hợp tác cùng phát triển -->
    <section class="cooperate-web wow fadeInUp ">
        <div class="container">
            <div class="title-section">
                <h2 class="wow fadeInUp ">Gói hợp tác cùng phát triển</h2>
                <p>Khi tham gia gói hợp tác cùng phát triển, Luật sư sẽ nhận về các
                    dịch vụ với các mức phí ưu đãi hấp dẫn, chuyên nghiệp...
                </p>
            </div>
            <div class="row cooperate-pc ">
                <div class="col-md-4">
                    <div class="box-cooperate">
                        <img class="icon-cooperate" src="../assets/images/iconhoptac1.svg">
                        <p class="title-cooperate">Lợi ích khi tham gia</p>
                        <ul>
                            <li>
                                <img class="tick" src="../assets/images/tick-hoptac.svg">
                                <span>Các dịch vụ với mức phí ưu đãi</span>
                            </li>
                            <li>
                                <img class="tick" src="../assets/images/tick-hoptac.svg">
                                <span>Profile miễn phí</span>
                            </li>
                            <li>
                                <img class="tick" src="../assets/images/tick-hoptac.svg">
                                <span>Phí môi giới thấp hấp dẫn</span>
                            </li>
                        </ul>
                        <div class="drop-left">
                        </div>
                        <div class="registers">
                            <a href="#" class="button btn-register"> Đăng ký ngay</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 ">
                    <img class="pc-cooperate" src="../assets/images/hoptacphattrien.svg" alt="" width="100%">

                </div>
                <div class="col-md-4">
                    <div class="box-cooperate">
                        <img class="icon-cooperate" src="../assets/images/iconhoptac2.svg">
                        <p class="title-cooperate">Phí dịch vụ
                            thường niên
                        </p>
                        <p class="prices">4.000.000đ/ tháng </p>
                        <div class="drop-right">
                        </div>
                        <div class="readmore-sale">
                            <a href="#" class="readmore"> Xem thêm ưu đãi</a>
                            <i class="ti-arrow-right"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row cooperate-tablet">
                <div class="col-md-6">
                    <div class="box-cooperate">
                        <img class="icon-cooperate" src="../assets/images/iconhoptac1.svg">
                        <p class="title-cooperate">Lợi ích khi tham gia</p>
                        <ul>
                            <li>
                                <img class="tick" src="../assets/images/tick-hoptac.svg">
                                <span>Các dịch vụ với mức phí ưu đãi</span>
                            </li>
                            <li>
                                <img class="tick" src="../assets/images/tick-hoptac.svg">
                                <span>Profile miễn phí</span>
                            </li>
                            <li>
                                <img class="tick" src="../assets/images/tick-hoptac.svg">
                                <span>Phí môi giới thấp hấp dẫn</span>
                            </li>
                        </ul>
                        <div class="drop-left">
                        </div>
                        <div class="registers">
                            <a href="#" class="button btn-register"> Đăng ký ngay</a>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="box-cooperate">
                        <img class="icon-cooperate" src="../assets/images/iconhoptac2.svg">
                        <p class="title-cooperate">Phí dịch vụ
                            thường niên
                        </p>
                        <p class="prices">4.000.000đ/ tháng </p>
                        <div class="drop-right">
                        </div>
                        <div class="readmore-sale">
                            <a href="#" class="readmore"> Xem thêm ưu đãi</a>
                            <i class="ti-arrow-right"></i>
                        </div>
                    </div>
                </div>
            </div>
            <svg class="line7" xmlns="http://www.w3.org/2000/svg" width="480" height="80" viewBox="0 0 480 80" fill="none">
                <path class="tenlualine1" d="M0.5 15V79H479V0" stroke="#2768EB" stroke-dasharray="6 6" />
            </svg>
            <svg class="line8" xmlns="http://www.w3.org/2000/svg" width="280" height="90" viewBox="0 0 280 90" fill="none">
                <path class="tenlualine1" d="M279 32V89H73.5V1H0" stroke="#2768EB" stroke-dasharray="6 6" />
            </svg>
        </div>
    </section>
    <!-- End Hợp tác cùng phát triển -->
    <section class="formresgiter">
        <div class="form-register">
            <div class="container">
                <div class="form-contact">
                    <div class="title-section">
                        <h2 class="wow fadeInUp">Đăng ký tư vấn</h2>
                        <p>Để biết thêm thông tin, vui lòng điền vào mẫu bên dưới,
                            đội ngũ chúng tôi sẽ liên hệ lại trong thời gian sớm nhất, xin cảm ơn!
                        </p>
                    </div>
                    
                    <form method="POST" id="form">
                        <div class="row">
                            <div class="col-md-12 py-0">
                                <div class="form-group">
                                    <input type="text" name="Fullname" class="form-control" id="Fullname" aria-describedby="emailHelp" placeholder="(*) Họ tên" required="">
                                </div>
                            </div>
                            <div class="col-md-12 py-0">
                                <div class="form-group">
                                    <input type="email" name="Email" class="form-control" id="Email" aria-describedby="emailHelp" placeholder="(*) Số điện thoại" required="">
                                </div>
                            </div>
                            <div class="col-md-12 py-0">
                                <div class="form-group">
                                    <input type="email" name="Email" class="form-control" id="Email" aria-describedby="emailHelp" placeholder="(*) Email" required="">
                                </div>
                            </div>
                            <div class="col-md-12 py-0">
                                <div class="custom-select">
                                    <select>
                                        <option value="0">Chọn loại dịch vụ cần tư vấn</option>
                                        <option value="1">Sao y công chứng</option>
                                        <option value="2">Hôn nhân và gia đình</option>
                                        <option value="3">Thủ tục hành chính</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-12 py-0 button-submitform">
                                <button type="submit" class="btn btn-primary" id="btnFormSubmit" data-toggle="modal" data-target="#contact-popup">Gửi
                                    <img src="../assets/images/gui.svg" alt=""></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- Modal dang ky ngay -->
    <div class="modal fade" id="modal-dang-ky-ngay" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Đăng ký gói dịch vụ</h5>
                    <p>Vui lòng nhập đầy đủ thông tin bằng tiếng việt có dấu vào form biểu mẫu phía dưới để nhận được tư vấn của luật sư</p>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <form method="POST" id="form-dang-ky-ngay" target="dummyframe" action="#">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" name="Fullname" class="form-control" id="Fullname" aria-describedby="emailHelp" placeholder="(*) Họ tên" required="">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="email" name="Email" class="form-control" id="Email" aria-describedby="emailHelp" placeholder="(*) Số điện thoại" required="">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="email" name="Email" class="form-control" id="Email" aria-describedby="emailHelp" placeholder="(*) Email" required="">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="custom-select">
                                    <select>
                                        <option value="0">Chọn loại dịch vụ </option>
                                        <option selected value="1">Gói cơ bản</option>
                                        <option value="2">Gói chuyên nghiệp</option>
                                        <option value="3">Gói cao cấp</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-12 button-submitform">
                                <button type="submit" class="btn btn-primary" id="btnFormSubmit" data-toggle="modal" data-target="#contact-popup">Gửi
                                    <img src="images/gui.svg" alt=""></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script >
export default{
    mounted() {
        document.body.classList.add('body-landingpage')
    },
}
</script>

