<template>
  <v-app id="app">
    <router-view/>
  </v-app>
  
</template>

<script>
export default {
  
  metaInfo: {
      title: 'Luatsu.com',
      titleTemplate: '%s | Luatsu.com - Luật sư của bạn!',
    }
}
</script>