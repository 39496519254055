<template>
<div class="container">
    <div class="row">
        <!--columns left-->
        <LeftSideBar />
        <!--columns center-->
        <div class="col-md-6 center-document">
            <div class="back">
                <router-link :to="{ name: 'Legals'}">
                    <img src="#" alt="">
                    <span>Trở lại danh sách</span>
                </router-link>
            </div>
            <div class="bg-white">
                <h1 class="title-large">{{ legal.document_title_vi }}</h1>
                <div class="tab-vbpl">
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link " data-toggle="tab" href="#menu1">Tóm tắt</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#menu2">Nội dung</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#menu3">VB gốc</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#menu4">Hiệu lực</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#menu5">VB liên quan </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#menu6">Lược đồ</a>
                        </li>
                    </ul>
                    <!-- Tab panes -->
                    <div class="tab-content">
                        <div id="menu1" class=" tab-pane active" v-html="legal.summary">
                        </div>
                        <div id="menu2" class=" tab-pane fade">
                            <br>
                            <div class="mo-ta" v-html="legal.document_content_vi"></div>
                        </div>
                        <div id="menu3" class=" tab-pane fade">
                            <h5 class="title-min">TẢI VĂN BẢN</h5>
                            <div class="downloadfile" v-for="(file, index) in splitedList" :key="index">

                                <img v-if="file.split('.').pop() === 'doc'" src="#" alt="">
                                <img v-if="file.split('.').pop() === 'pdf'" src="#" alt="">

                                <p class="title-min4">
                                    <a v-bind:data-identity="file" @click="downloadFile({filePath: file, fileName: file.split('/').pop() })">{{ file.split('/').pop() }}</a>

                                </p>
                            </div>
                        </div>
                        <div id="menu4" class=" tab-pane fade">
                            <p class="title-min">{{ legal.validity_status }}</p>
                        </div>
                        <div id="menu5" class=" tab-pane fade">
                            <p class="title-min">THUỘC TÍNH VĂN BẢN 4</p>
                        </div>
                        <div id="menu6" class=" tab-pane fade">
                            <p class="title-min">THUỘC TÍNH VĂN BẢN 5</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--columns right-->
        <div class="col-md-4 right-home">
            <div class="list-right chitiet-vbpl">
                <p>Cùng chuyên mục</p>
                <ul v-if="Legals">
                    <li v-for="legals in Legals.data" :key="legals.id">
                        <a :href="$router.resolve({name: 'LegalDetail', params: { id: legals.id }}).href">
                            <img src="#" />{{legals.document_title_vi.substring(0, 70) + ".."}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapGetters,
    mapActions
} from "vuex";
import LeftSideBar from '../components/LeftSideBar.vue';
export default {
    name: 'Legal',
    components: {
        LeftSideBar
    },
    metaInfo() {
        return {
            title: this.legal.document_title_vi,
        }
    },
    created: function () {
        this.CurentLegalDocuments(this.$route.params.id),
            this.GetLegalDocuments({
                page: 1,
                perPage: 10
            })
    },
    computed: {
        ...mapGetters({
            legal: "StateCurentLegalDocument",
            Legals: "StateLegalDocuments"
        }),
        splitedList() {
            let newArr = this.legal.original_file.split(',')
            return newArr
        }
    },
    methods: {
        ...mapActions(["CurentLegalDocuments", "GetLegalDocuments", "downloadFile"])
    }
};
</script>
