<template>
<div class="box-profile-ls">
    <div class="tags-ls">
        <div class="total-star">
            <v-rating color="#59A3EF" :value="data.rating_point" background-color="#ebebeb" class="pa-0" medium readonly></v-rating>
        </div>
        <div class="tags">
            <a v-for="(t, index) in data.tags" :key="index" href="">{{t}}</a>
        </div>
    </div>
    <div class="name-ls-nx">
        <h6 v-if="raters">{{raters.name}}</h6>
        <h6 v-else>Người dùng ẩn danh</h6>
        <span class="time-nx">{{moment(data.createdAt).startOf('day').fromNow()}}</span>
    </div>
    <p class="detail-nx">{{data.content}} </p>
    <button v-if="login_user" class="like-nx-active" @click="reaction(login_user._id,data._id,data.is_reaction)">
        <i v-if="data.is_reaction" class="fas fa-heart"></i>
        <i v-else class="far fa-heart"></i>
        {{data.num_of_reactions}}
    </button>
    <button v-else class="like-nx-active">
        <i v-if="data.is_reaction" class="fas fa-heart"></i>
        <i v-else class="far fa-heart"></i>
        {{data.num_of_reactions}}
    </button>
    <!-- <button disabled class="rep-nx-active">Trả lời</button>
    <button class="xem-them-nx" @click="show = !show" v-if="data.num_of_reply_content > 0">
        <img src="../../assets/images/reply-nx.svg"> {{data.num_of_reply_content}} phản hồi 
    </button>
    <div v-show="show" class="nx-c2" v-for="(r, index) in replies.ratings" :key="index">
        <div class="name-ls-nx">
            <span class="time-nx">{{moment(r.createdAt).startOf('day').fromNow()}}</span>
        </div>
        <p class="detail-nx">{{r.content}}</p>
        <button class="like-nx-active">
            <v-icon dark>mdi-heart-outline</v-icon>{{r.num_of_reactions}}
        </button>
        <button class="rep-nx-active">Trả lời</button>
    </div>
    <div id="box-reply-nx">
        <input placeholder="Phản hồi của bạn...">
        <button disabled type="submit" class="send-nx">Gửi</button>
    </div> -->
</div>
</template>

<script>
import axios from "axios";
import R from './rating'
export default {
    data() {
        return {
            raters: '',
            replies: '',
            show: false,
            login_id: ''
        }
    },
    props: ['data', 'login_user'],
    created: function () {
        this.getUser()
        if (this.data.num_of_reply_content > 0) {
            this.getReplies()
        }
    },
    methods: {
        async getUser() {
            await axios.get(`/v2/users/socId/${this.data.raters}`)
                .then((response) => {
                    if (response.data.status == 200) {
                        this.raters = response.data.data;
                    }
                })
        },
        async getReplies() {
            try {
                if (this.login_user) {
                    this.login_id = `?current_user_id=${this.login_user._id}`
                }
                let r = await R.getReplies(this.data._id, this.login_id);
                this.replies = r.data.data.results;
                console.log(r);
            } catch (error) {
                alert(error);
            }
        },
        async reaction(l, r, i) {
            if (i == true) {
                this.data.num_of_reactions--;
                this.data.is_reaction = false
            } else {
                this.data.num_of_reactions++
                this.data.is_reaction = true;
            }
            try {
                await R.reactions(l, r, i);
            } catch (error) {
                alert(error);
            }
        }
    }
}
</script>
