<template>
<div class="container">
    <div class="row">
    <div class="col">
        <StatusList />
    </div>
    </div>
</div>
</template>
<script>
import StatusList from '../components/Status/StatusLists.vue';
export default {
    components:{
        StatusList
    }
}
</script>