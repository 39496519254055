<template>
<div style="margin-top:100px">
    <!-- <div v-if="User" class="item-box stt">
        <form @submit.prevent="submit" ref="content">
            <div>
                <textarea placeholder="Hãy thêm vấn đề pháp lý của bạn?" v-model="form.content"></textarea>
                <img class="ava-post" src="src/assets/images/tick1.svg">
            </div>
            <div class="action-post">
                <p>
                    <input type="file"  class="form-control fea" v-on:change="onChange">
                    <a href="#"><img src="src/assets/images/add-img.svg"></a>
                    <a href="#"><img src="src/assets/images/add-video.svg"></a>
                </p>
                <button type="submit">Đăng</button>
            </div>
        </form>
    </div> -->
   <p v-for="(m, index) in Posts" :key="index">
       {{m.id}}
   </p>
    <!-- {{Posts}} -->
    <!-- <StatusCard v-for="(status, index) in Posts" :key="index" :id="status.id" :_id="status._id" :content="status.content" :user="status.user" :created_at="status.created_at" :featured_images="status.featured_images" /> -->
    <!-- <infinite-loading  @infinite="infiniteScroll"></infinite-loading> -->
</div>
</template>

<script>
import axios from "axios";
import {
    mapGetters,
    mapActions
} from "vuex";
// import InfiniteLoading from 'vue-infinite-loading';
// import StatusCard from './Status.vue';
export default {
    data() {
        return {
            image:'',
            page: 1,
            form: {
                file:'',
                content: ''
            },
            // post_m:''
        }
    },
    components: {
        // StatusCard,
        // InfiniteLoading
    },
    created: function () {
        this.GetPosts(this.page)
        // this.GetNews()
        
        // this.post_m =  this.Posts.concat(this.News)
        // this.post_m.sort(function (a, b) {
        //     return a.id - b.id
        // })
    },
    computed: {
        url() {
            return (
                "https://apistaging.luatsu.com/api/v1/law/news?page=" + this.page
            );
        },
        ...mapGetters({
            Posts: "StatePosts",
            User: "StateUser",
            // News: "StateNews"
        }),

    },
    methods: {
        // onChange() {
            // var a = document.querySelector('.fea')
            // this.form.file = a.files[0]
            // this.form.file = e.target.files[0];
        // },
        
        ...mapActions(["CreatePost", "GetPosts"]),
        async submit() {
            console.log(this.post_m)
            // try {
            //     const Status = new FormData()
            //     Status.append('featured_image', this.form.file)
            //     Status.append('content', this.form.content)
            //     Status.append('user_id', this.User.id)
            //     await this.CreatePost(Status);
            //     this.$refs.content.reset();
            // } catch (error) {
            //     throw "Sorry you can't make a post now!"
            // }
        },
        infiniteScroll($state) {
            setTimeout(() => {
                this.page++
                axios
                    .get(this.url)
                    .then((response) => {
                        if (response.data.data.length >= 1) {
                            response.data.data.forEach((item) => this.Posts.push(item));
                            $state.loaded();
                        } else {
                            $state.complete();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 200);
        },

    }
}
</script>