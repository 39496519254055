<template v-if="User">
	<!-- Main-content -->
    <div class="TT-tai-khoan set-min-height set-seacrh-ls">
        <div class="container">
            <div class="row">
                <!--columns left-->
                <LeftSideBackend />
                <!--columns center-->
                <div class="col-md-9 center-home edit-tk bg-tk-white">
                    <div class="edit-ava">
                            <img v-if="User.avatar" :src="User.avatar.path">
                        <img v-else src="https://r9b7u4m2.stackpathcdn.com/prod/sites/eXfkOOiYH-uoddxClSi52viuasTF1mJ8olZ0u-tOtfFqK66gZCc90Ly_Uoc0VmR1eULwQ0uGf2JhPt4yPTts8A/themes/base/assets/images/avatar-1.png">
                        <img class="icon-edit" src="#">
                    </div>
                    <form class="edit-TTtaikhoan">
                        <div class="row">
                            <div class="form-group col-md-4">
                                <label for="ten">Tên hiển thị</label>
                                <input type="text" class="form-control" :value="User.name" id="ten">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="email">Email</label>
                                <input type="text" class="form-control" :value="User.email" id="email">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="sdt">Số điện thoại</label>
                                <input type="text" class="form-control" :value="User.telephone" id="sdt">
                            </div>
                            <div class="form-group col-md-8">
                                <label for="diachi">Địa chỉ</label>
                                <input type="text" class="form-control" :value="User.places_address" id="diachi">
                            </div>
                            
                        </div>
                        <div class="form-group form-check col-md-12">
                            <label class="style-check">
                                <input type="checkbox" checked="checked">
                                Thay đổi mật khẩu
                                <span class="checkmark"></span>
                            </label>
                            <!-- <input type="checkbox" class="form-check-input" id="">
                           <label class="form-check-label" for="exampleCheck1">Thay đổi mật khẩu</label> -->
                        </div>
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-primary btn-editTK">Gửi</button>
                        </div>
                    </form>
                    <!-- Thay DOi MAt Khau -->
                    <div class="thaydoi-mk">
                        <form class="edit-TTtaikhoan">
                            <div class="form-group col-md-4">
                                <label for="matkhaucu">Mật khẩu cũ</label>
                                <input type="password" class="form-control" placeholder="*****" id="matkhaucu">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="matkhaucmoi">Mật khẩu mới</label>
                                <input type="password" class="form-control" placeholder="*****" id="matkhaumoi">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="confirm-matkhau">Nhập lại mật khẩu mới</label>
                                <input type="password" class="form-control" placeholder="*****" id="confirm-matkhau">
                            </div>
                            <div class="col-md-12 capnhat-mk">
                                <button type="submit" class="btn btn-primary btn-huy">Huỷ</button>
                                <button type="submit" class="btn btn-primary btn-save">Lưu lại</button>
                            </div>
                        </form>
                    </div>
                    <!-- Thay DOi MAt Khau -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import LeftSideBackend from "../components/LeftSideBackend.vue"; 
export default {
  name: 'Accounts',
  components: {
    LeftSideBackend
  },
  metaInfo: {
         title: "Tài khoản",
    },
  // data() {
  //   return {
  //     form: {
        
  //     }
  //   };
  // },
  // created: function () {
  //   // a function to call getposts action
  //   this.GetPosts()
  // },
  computed: {
    ...mapGetters({User: "StateUser"}),
  },
  // methods: {
  //   ...mapActions(["CreatePost", "GetPosts"]),
  //   async submit() {
  //     try {
  //       await this.CreatePost(this.form);
  //     } catch (error) {
  //       throw "Sorry you can't make a post now!"
  //     }
  //   },  
  // }
};
</script>