import Repository from '../Repository';

const resource = "/questions";

export default {
    get(perPage,page) {
        return Repository.get(`${resource}?perPage=${perPage}&page=${page}`);
    },
    getByID(id){
        return Repository.get(`${resource}/${id}`);
    }, 
    getByAuthor(authorID){
        return Repository.get(`${resource}/author/${authorID}`);
    },
    create(question){
        return Repository.create(`${resource}`,question);
    }
}