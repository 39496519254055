<template>
    <div>
      <div id="frmOtp">
          <form @submit.prevent="submit" class="forgot">
              <div class="form-group" style="position: relative;">
                  <input type="text" name="phone" id="phone" class="form-control" placeholder="Số điện thoại" v-model="form.phone">
                  <input type="hidden" name="otp" v-model="form.otp">
              </div>
              <div class="btn-sign">
                  <button class="submit">OTP</button>
              </div>
              <a data-toggle="tab" href="#dangnhap" aria-expanded="false" role="tab" aria-controls="home" @click="passTab()">
                  <p class="forgot-password">Đăng nhập ?</p>
              </a>
          </form>
      </div>
      <div id="inputOTP" style="display: none">
          <form @submit.prevent="submitOTP">
          <div class="form-group" style="display: inline-flex;margin: 0 15%;">
              <input type="text" class="otp form-control" @keyup="focusInput(1)">
              <input type="text" class="otp form-control" @keyup="focusInput(2)">
              <input type="text" class="otp form-control" @keyup="focusInput(3)">
              <input type="text" class="otp form-control" @keyup="submitOTP()">
              <p class="timeout">(120)</p>
          </div>
          </form>
      </div>
      <p v-if="showError" id="errorFrmOTP" style="color:red;font-size:14px;text-align: center;margin-top: 50px;">OTP không đúng</p>
    </div>
</template>
<style scoped>
.timeout {
    color: #777;
    display: none;
    margin: 10px;
}
#inputOTP input {
    margin: 0 15px;
    width: 50px;
}
</style>
<script>
import { mapActions } from "vuex";
import $ from 'jquery';
export default {
    name: "Auth",
    components: {},
    data() {
        return {            
            form: {
                otp: "",
                phone: ""                
            },
            timer: null,
            totalTime: 120,
            timeRun: false,
            showError: false
        };
    },
    methods: {
        ...mapActions(["sendSMS", "verifyOtp"]),
        async submit() {
            const Form = new FormData();
            Form.append("phone", this.form.phone);
            try {
                let ajax = await this.sendSMS(Form);
                if (ajax.status === 200 && ajax.data.error === 0) {
                    $('#inputOTP').attr('style', 'display:block');
                    $('p.timeout').attr('style', 'display:block');
                    $('#frmOtp').attr('style', 'display:none');
                    this.timer = setInterval(()=>this.countdown(), 1000);
                    this.timeRun = true;
                    $('#inputOTP input:first').focus();
                }                
                this.showError = false
            } catch (error) {
                console.log(error);
                this.showError = true
            }
        },
        countdown: function() {
            this.totalTime--;
            if (this.totalTime === 0) {
                clearInterval(this.timer);
                this.timer = null;
            }
            $('p.timeout').html('(' + this.totalTime + ')');
        },
        focusInput(i) {
            $('#inputOTP input:eq(' + i + ')').focus();
        },
        passTab() {
            $('a[data-toggle="tab"]').removeClass("active");
            $('#nameTab').html("Đăng nhập");
        },
        async submitOTP() {
            this.form.otp = $('#inputOTP input:eq(0)').val() + $('#inputOTP input:eq(1)').val() +
              $('#inputOTP input:eq(2)').val() + $('#inputOTP input:eq(3)').val();

            let FormOTP = new FormData();
            FormOTP.append("otp", this.form.otp);
            FormOTP.append("phone", this.form.phone);
            try {
                let ajax = await this.verifyOtp(FormOTP);
                if (ajax.status === 200) {
                    if (ajax.data.error === 0) {
                        this.showError = false;
                    } else {
                        $('#errorFrmOTP').text(ajax.data.message);
                        this.showError = true;
                    }
                }
            } catch (error) {
                console.log(error);
                this.showError = true
            }
        }
    },
};
</script>