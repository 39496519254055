import axios from "axios";

const state = {
    sms: null,
    users: null,
    posts: null,
    comments: null,
    news: null,
    newsById: null,
    legals: null,
    legalDocuments: null,
    curentLegal: null,
    curentLegalDocument: null,
    lawyers: null,
    curentLawyers: null,
    cateParents: null,
};

const getters = {
    isAuthenticated: (state) => !!state.users,
    StatePosts: (state) => state.posts,
    StateComments: (state) => state.comments,
    StateUser: (state) => state.users,
    StateNews: (state) => state.news,
    StateNewDetail: (state) => state.newsById,
    StateLegals: (state) => state.legals,
    StateLegalDocuments: (state) => state.legalDocuments,
    StateCurentLegal: (state) => state.curentLegal,
    StateCurentLegalDocument: (state) => state.curentLegalDocument,
    StateLawyers: (state) => state.lawyers,
    StateCurentLawyers: (state) => state.curentLawyers,
    StateSMS: (state) => state.sms,
    StateCateParents: (state) => state.cateParents,
};
// function handle get methods for axios
function getDataPaginationFromApi({ commit }, url, valueSet) {
    const axios = require('axios');
    let data = '';
    let config = {
        method: 'get',
        mode: 'no-cors',
        url: url,
        headers: {},
        credentials: 'same-origin',
        data: data,
        timeout: 1000,
    };
    axios(config)
        .then(response => {
            commit(valueSet, response.data);
        })
        .catch((error) => {
            console.log(error);
        });
}

// function saveDataFromApi({ dispatch }, url, method, headers, formData , valueSet) {
//     const axios = require('axios');
//     let config = {
//         method: method,
//         mode: 'no-cors',
//         url: url,
//         headers:headers,
//         credentials: 'same-origin',
//         data: formData
//     };
//     dispatch(valueSet,axios(config)
//         .then()
//         .catch((error) => {
//             console.log(error);
//         }));
// }

// api action
const actions = {
    async sendSMS({ commit }, form) {
        let sendSMS = await axios.post('v2/sendOtp', form);
        await commit("sendSMS", sendSMS.data);
        return sendSMS;
    },
    async verifyOtp({ commit }, form) {
        let verify = await axios.post('v2/verifyOtp', form);
        await commit("sendSMS", verify.data);
        return verify;
    },
    async Register({ commit }, UserForm) {
        let a = await axios.post('api/auth/register', UserForm)
        await commit("Register");
        return a;

    },
    async LogIn({ dispatch }, user) {
        let users = await axios.post("api/auth/login", user);
        return await dispatch("userByID", users.data.user.id);
    },
    async userByID({ commit }, id) {
        let users = await axios.get(`/v2/users/id/${id}`);
        await commit("setUser", users.data.data);
    },
    async CreatePost({ dispatch }, post) {
        await axios.post("api/v1/status", post);
        return await dispatch("GetPosts");
    },
    async GetPosts({ commit }, page) {
        let response = await axios.get("api/v1/status?page=" + page);
        commit("setPosts", response.data.data);
    },
    async GetComments({ commit }) {
        const axios = require('axios');
        let data = '';
        let config = {
            method: 'get',
            url: 'http://172.16.161.20:9904/posts/60dc2096a958f666647cc0c7/LIKE',
            headers: {
                'Content-Type': 'application/json',
                'x-product': 'SOCIAL',
                'x-token': '9b826ecd21657b352e8a87e0b3b03a9d4086517c'
            },
            data: data
        };
        await axios(config)
            .then((response) => {
                commit("setComments", response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    },
    async GetNews({ commit }, page) {
        let url = '/api/v1/law/news?perPage=5&page=' + (!page ? page : 1);
        getDataPaginationFromApi({ commit }, url, "setNews")
    },
    async GetCategoryParents({ commit }) {
        let url = '/api/v1/law/categories/parent';
        getDataPaginationFromApi({ commit }, url, "setCateParent")
    },
    async CurentNewDetail({ commit }, id) {
        let response = await axios.get(`/api/v1/law/news/${id}`);
        commit('setNewDetail', response.data[0]);
    },
    async GetLegalDocuments({ commit }, options) {
        let response = await axios.get("/api/v1/law/legal-document?page=" + options.page + "&perPage=" + options.perPage);
        commit("setLegalDocuments", response.data);
    },
    async CurentLegalDocuments({ commit }, id) {
        let response = await axios.get(`/api/v1/law/legal-document/${id}`);
        commit('setCurentLegalDocument', response.data);
    },
    async GetLegals({ commit }) {
        let response = await axios.get("/api/v1/law/legal-crawl");
        commit("setLegals", response.data);
    },
    async CurentLegals({ commit }, id) {
        let response = await axios.get(`/api/v1/law/legal-crawl/${id}`);
        commit('setCurentLegal', response.data);
    },
    async GetLawyers({ commit }) {
        let response = await axios.get("/api/v1/users/list");
        commit("setLawyers", response.data);
    },
    async CurentLawyers({ commit }, slug) {
        let response = await axios.get(`/v2/users/slug/${slug}`);
        commit('setCurentLawyers', response.data.data);
    },
    async LogOut({ commit }) {
        let users = null;
        commit("logout", users);
    },
    async downloadFile({ commit }, fileConfig) {
        var config = {
            method: 'get',
            url: 'https://cdn.luatsu.com/uploads/get-legal-file?file_path=' + fileConfig.filePath,
            responseType: 'blob',
            headers: {
                'x-product': 'LAW_UPLOADS',
                'x-token': '6e1d900c143172440cc12a24e6b8c072cf166dkf'
            }
        };

        await axios(config)
            .then(function(response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileConfig.fileName);
                document.body.appendChild(link);
                link.click();
                commit();
            });
    },
};

const mutations = {
    Register() {},
    sendSMS(state, sms) {
        state.sms = sms
    },
    setUser(state, users) {
        state.users = users;
    },
    setPosts(state, posts) {
        state.posts = posts;
    },
    setNews(state, news) {
        state.news = news;
    },
    setComments(state, comments) {
        state.comments = comments;
    },
    setNewDetail(state, newsById) {
        state.newsById = newsById;
    },
    setLegalDocuments(state, legalDocuments) {
        state.legalDocuments = legalDocuments;
    },
    setCurentLegalDocument(state, curentLegalDocument) {
        state.curentLegalDocument = curentLegalDocument;
    },
    setLegals(state, legals) {
        state.legals = legals;
    },
    setCurentLegal(state, curentLegal) {
        state.curentLegal = curentLegal;
    },
    setLawyers(state, lawyers) {
        state.lawyers = lawyers;
    },
    setCurentLawyers(state, curentLawyers) {
        state.curentLawyers = curentLawyers;
    },
    logout(state, users) {
        state.users = users;
    },
    setCateParent(state, cateParents) {
        state.cateParents = cateParents;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};