<template>
<div>
    <div class="header-homepage header-top" v-bind:class="{ 'show-menu': isActive1 }">
        <div class="menu-mobile" v-on:click="isActive1 = !isActive1">
            <i class="ti-menu"></i>
            <i id="close-menu" class="ti-close"></i>
        </div>
        <div class="header-left" style="display:flex">
            <div class="logo">
                <a href="/"><img src="../../assets/images/logo-white.svg"></a>
            </div>
            <!-- Search-->
            <div class="search-box" v-bind:class="{ active: isActive }">
                <div class="h-search">
                    <i class="ti-search"></i>
                    <input type="text" placeholder="Bạn đang tìm gì?" name="search" autocomplete="off" v-model="searchValue" @keyup.enter="onSearch" @keyup="search">
                    <v-list v-show="showSearchHistory" nav dense class="p-0">
                        <v-list-item v-for="(item, index) in recentList" :key="index">
                            <v-list-item-avatar class="my-0">
                                <v-img max-height="20" max-width="20" src="../../assets/images/recent.svg"></v-img>
                            </v-list-item-avatar>

                            <v-list-item-content @click="searchRecent(item)" style="cursor:pointer">
                                <v-list-item-title v-text="item"></v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action class="my-0">
                                <v-btn icon @click="removeCat(item)">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item v-for="l in law" :key="l.id">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <router-link :to="'/profile/'+l._source.id">{{l._source.name}}</router-link>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>
            </div>
        </div>
        <div class="header-right">
            <div class="menu">
                <ul>
                    <li v-for="(item, index) in menu.menuItem" :key="index">
                        <a :href="item.url"><img :src="item.icon">{{item.name.toUpperCase()}}</a>
                    </li>
                </ul>
            </div>
            <div class="infor-contact">
                <a href="tel:0819381126" class="hotline" style="display:flex"><img :src="menu.hotline.icon">{{menu.hotline.phone}}</a>
                <a :key="index" v-for="(s, index) in menu.social" :href="s.url">
                    <img :src="s.icon" alt="">
                </a>

            </div>
            <div v-if="isLoggedIn" class="d-flex justify-content-end align-items-center ml-3">
                <v-menu transition="slide-y-reverse-transition" bottom offset-y min-width="320" max-height="50vh">
                    <template v-slot:activator="{ on }">
                        <v-btn icon class="mr-3" v-on="on">
                            <v-badge dot offset-x="5" offset-y="5">
                                <v-icon color="#fff">mdi mdi-bell-outline</v-icon>
                            </v-badge>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-list>
                            <v-list-item>
                                <v-list-item-title>Hoài Ân</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>Hoài Ân</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
                <v-menu transition="slide-y-reverse-transition" bottom offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                            <v-avatar size="35">
                                <img v-if="User.avatar" :src="User.avatar.path" alt="avatar">
                                <img v-else src="../../assets/images/ls-avatar.png" alt="">
                            </v-avatar>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="logout">
                                Đăng xuất
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </div>
            <!-- End Login -->

            <div v-else class="sign-in-out">
                <!-- <router-link to="/register" type="button" class="sign-in ">Đăng ký</router-link>
            <router-link to="/login" type="button" class="sign-out">Đăng nhập</router-link> -->
                <button type="button" class="sign-in " data-toggle="modal" data-target="#modal-login-logout" @click="handleClick(0)">Đăng ký</button>
                <button type="button" class="sign-out" data-toggle="modal" data-target="#modal-login-logout" @click="handleClick(1)">Đăng nhập</button>
            </div>
        </div>

    </div>
    <div class="modal fade" id="modal-login-logout" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="login-page">
                        <div class="tab-login">
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item ">
                                    <a class="nav-link" v-bind:class="{ 'active': activeTab == 1 }" data-toggle="tab" href="#dangnhap" aria-expanded="false">Đăng nhập</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" v-bind:class="{ 'active': activeTab == 0 }" data-toggle="tab" href="#dangky" aria-expanded="false">Đăng ký</a>
                                </li>

                            </ul>
                            <!-- Tab panes -->
                            <div class="tab-content">
                                <div id="dangnhap" class="tab-pane" v-bind:class="{ 'active': activeTab == 1 }">
                                    <login></login>
                                </div>
                                <div id="dangky" class="tab-pane" v-bind:class="{ 'active show': activeTab == 0 }">
                                    <register></register>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end Header -->
</div>
</template>

<script>
import {
    mapGetters
} from "vuex";
import axios from "axios";
import Login from "../Auth/Login.vue";
import Register from "../Auth/Register.vue";
export default {
    data() {
        return {
            menu: {
                menuItem: [{
                        name: 'Tìm luật sư',
                        url: '/map',
                        icon: require('../../assets/images/h-map.svg')
                    }, {
                        name: 'Dành cho luật sư',
                        url: '/for-law',
                        icon: require('../../assets/images/h-auction.svg')
                    }, {
                        name: 'Hỏi - Đáp',
                        url: '#',
                        icon: require('../../assets/images/h-question.svg')
                    },
                    {
                        name: 'Tin tức',
                        url: '/news',
                        icon: require('../../assets/images/h-book.svg')
                    }
                ],
                hotline: {
                    icon: require('../../assets/images/h-hotline.svg'),
                    phone: '0819381126'
                },
                social: [{
                        title: 'Facebook',
                        icon: require('../../assets/images/light-facebook.svg'),
                        url: 'https://www.facebook.com/luatsucom'
                    },
                    {
                        title: 'Instagram',
                        icon: require('../../assets/images/light-instagram.svg'),
                        url: '#'
                    },
                    {
                        title: 'Zalo',
                        icon: require('../../assets/images/light-zalo.svg'),
                        url: '#'
                    },
                    {
                        title: 'Tiktok',
                        icon: require('../../assets/images/light-tiktok.svg'),
                        url: '#'
                    }
                ]
            },
            isActive: false,
            isActive1: false,
            cats: [],
            searchValue: '',
            searchHistory: [],
            showSearchHistory: false,
            searchHistoryMaxLength: 5,
            law: [],
            activeTab: null
        };
    },
    components: {
        Login,
        Register
    },
    computed: {
        isLoggedIn: function () {
            return this.$store.getters.isAuthenticated
        },
        ...mapGetters({
            User: "StateUser"
        }),
        recentList() {
            return this.cats.filter(recent => {
                return recent.toLowerCase().includes(this.searchValue.toLowerCase())
            })
        }
    },
    mounted() {
        if (localStorage.getItem('searchRecent')) {
            try {
                this.cats = JSON.parse(localStorage.getItem('searchRecent'));
            } catch (e) {
                localStorage.removeItem('searchRecent');
            }
        }
    },
    methods: {
        handleClick(id) {
            this.activeTab = id;
        },
        async logout() {
            await this.$store.dispatch('LogOut')
            this.$router.go('/')
        },
        // onSearch() {
        //     window.location.href = "/search?k=" + this.searchValue;
        // },
        processSearch() {
            if (this.searchHistory.indexOf(this.searchValue) === -1) {
                this.searchHistory.push(this.searchValue);
                if (this.searchHistory.length > this.searchHistoryMaxLength) {
                    // Remove the first (oldest) element
                    this.searchHistory.shift();
                }
            }
            this.searchValue = '';
        },
        removeCat(x) {
            this.cats.splice(x, 1);
            this.saveCats();
        },
        saveCats() {
            let parsed = JSON.stringify(this.cats);
            localStorage.setItem('searchRecent', parsed);
        },
        selectPreviousSearch(index) {
            this.searchValue = this.searchHistory[index];
            this.showSearchHistory = false;
        },
        clearHistory() {
            this.searchHistory = [];
            this.searchValue = '';
        },
        onClick() {
            this.showSearchHistory = !this.showSearchHistory;
            this.isActive = !this.isActive;
        },
        onInput() {
            this.showSearchHistory = false;
            this.isActive = false;
        },
        onSearch() {
            window.location.href = "/search?k=" + this.searchValue;
        },
        search() {
            this.showSearchHistory = !this.showSearchHistory;
            this.isActive = !this.isActive;
            let config = {
                method: 'get',
                url: 'https://apistaging.luatsu.com/api/v1/search?keywords=' + this.searchValue
            };
            axios(config)
                .then((response) => {
                    return this.law = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        searchRecent(x) {
            window.location.href = "/search?k=" + x;
        }
    },
}
</script>

<style>
.login a {
    color: #303943;
    font-size: 14px;
    height: 100%;
    padding: 0 10px;
}

.login-page .tab-login .nav-tabs {
    padding-left: 0;
}

@media only screen and (max-width: 1400px) {
    .header-top .menu-mobile {
        top: 28px;
    }
}
</style>
