<template>
  <div v-if="isLoggedIn" class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Đặt Câu Hỏi Tư Vấn</h5>
      <p>
        Vui lòng nhập đầy đủ thông tin bằng tiếng việt có dấu vào form biểu mẫu
        phía dưới để nhận được tư vấn của luật sư
      </p>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form
        method="POST"
        id="form-dat-cau-hoi"
        target="dummyframe"
        v-on:submit.prevent="submitForm"
      >
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <input
                type="text"
                name="Fullname"
                :v-model="form.full_name"
                class="form-control"
                id="Fullname"
                placeholder="Họ tên *"
                autocomplete="off"
                required
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <input
                type="text"
                :v-model="form.topic"
                name="Topic"
                class="form-control"
                id="Topic"
                placeholder="Tiêu đề câu hỏi"
                autocomplete="off"
                required
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <textarea
                type="text"
                :v-model="form.content"
                name="Note"
                class="form-control"
                id="Note"
                placeholder="Nội dung tư vấn "
                autocomplete="off"
              ></textarea>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <div class="custom-file">
                <input
                  type="file"
                  class="custom-file-input"
                  :v-model="form.thumbnails"
                  autocomplete="off"
                  id="file"
                  multiple
                />
                <label class="custom-file-label" for="file">
                  <!-- <img src="../assets/images/uploadfile.svg" /> -->
                  Ảnh minh họa</label
                >
              </div>
              <ul id="fileList" class="file-list"></ul>
            </div>
          </div>
          <div class="col-md-12 btn-submit-question">
            <button
              type="submit"
              class="btn"
              id="btnFormSubmit"
              data-toggle="modal"
              data-target="#contact-popup"
            >
              Gửi
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- v-else  -->
  <div v-else class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Đặt Câu Hỏi Tư Vấn</h5>
      <p>Vui lòng nhập đăng nhập để nhận được tư vấn của luật sư</p>
      <label><a href="/login">Đăng Nhập</a></label>
      &nbsp;
      <label><a href="/register">Đăng Ký</a></label>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "News",
  data() {
    return {
      form: {
        full_name: null,
        topic: null,
        content: null,
        thumbnails: null,
      },
    };
  },
  components: {},
  metaInfo: {},
  created: function () {},
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    submitForm() {
      axios
        .post("/api/v1/law/questions/create", this.form)
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
  },
  mounted: function () {},
};
</script>