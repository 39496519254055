import axios from "axios";
export const baseUrl = process.env.VUE_APP_SOCIAL_API_URL + "/users/"
export const headers = {
    'Content-Type': process.env.VUE_APP_SOCIAL_API_TYPE,
    'x-product': process.env.VUE_APP_SOCIAL_API_PRODUCT,
    'x-token': process.env.VUE_APP_SOCIAL_API_TOKEN
}
export default {
    sendRating(data) {
        let config = {
            url: `${baseUrl}rating`,
            method: 'post',
            headers: headers,
            data: data
        };
        return axios(config);

    },
    returnRatingDetail(id) {
        let config = {
            url: baseUrl + id + '/rating/detail',
            headers: headers,
        };
        return axios(config);
    },
    returnRating(id, u_id) {
        let config = {
            url: `${baseUrl}${id}/LOVE/rating${u_id}`,
            headers: headers
        };
        return axios(config)
    },
    getReplies(id, u_id) {
        let config = {
            url: baseUrl + '/rating/replies/' + id + '/LOVE/' + u_id,
            headers: headers
        };
        return axios(config)
    },
    reactions(l, r, i) {
        let mValue = '';
        if (i == true) {
            mValue = 'delete'
        } else {
            mValue = 'post'
        }
        let data = JSON.stringify({
            "user_id": l,
            "rating_content_id": r,
            "reaction_type": "LOVE"
        });

        let config = {
            method: mValue,
            url: baseUrl + 'rating/reactions',
            headers: headers,
            data: data
        };
        return axios(config)
    }
}