<template>
<div class="main-tt">
    <section class="section-hoi-dap">
        <div class="content-question">
            <div class="container">
                <div class="breadcrumbs">
                    <a href="index.html"><i class="ti-home"></i></a>
                    <i class="ti-angle-right"></i>
                    <a href="news.html">Hỏi đáp</a>
                </div>
                <div class="row">
                    <list-news></list-news>
                    <div class="col-md-3">
                        <div class="right-ques">
                            <div class="button btn-question" data-toggle="modal" data-target="#modal-dat-cau-hoi">
                                <img src="../assets/images/icon-ques.svg" />Đặt câu hỏi
                            </div>
                            <div class="danh-muc-ques" v-if="listCate">
                                <h4 class="title-right-ques">Danh mục</h4>
                                <ul class="pl-0">
                                    <!-- <li class="active"> -->
                                    <li v-for="cate in listCate" :key="cate.id" class="">
                                        <a href="#" :alt="cate.name" :title="cate.name">{{ cate.name.substring(0,30) }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal dat cau hoi -->
        <div class="modal fade" id="modal-dat-cau-hoi" role="dialog">
            <div class="modal-dialog" role="document">
               <question-form></question-form>
            </div>
        </div>
    </section>
</div>
</template>
<script>
import {
    mapGetters,
    mapActions
} from "vuex";
import listNews from "../components/news/list-news.vue"
import questionForm from "../components/news/question-form.vue"
// import axios from "axios";
export default {
    name: "News",
    data() {
        return {
            listCate: null,
            page: 1,
            form: {
                full_name: null,
                topic: null,
                content: null,
                thumbnails: null,
            },
        };
    },
    components: {
        listNews,
        questionForm
    },
    metaInfo: {
        title: "Tin tức pháp luật",
    },
    created: function () {
        this.listCate = this.CateParents;
    },
    computed: {
        ...mapGetters({
            CateParents: "StateCateParents"
        })
    },
    methods: {
        ...mapActions(["GetCategoryParents"]),
    },
    mounted: function () {
        this.GetCategoryParents();
    }
};
</script>
