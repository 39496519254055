<template>
    <div class="body-homepage">
        <DashboardNavbar />
        <router-view></router-view>
        <ContentFooter />
    </div>
</template>
<script>
import DashboardNavbar from "./home-nav.vue";
import ContentFooter from "./home-footer.vue";
export default {
    components:{
        DashboardNavbar,
        ContentFooter
    }
}
</script>