import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Register from "../views/Register";
import Login from "../views/Login";
import LegalDetail from "../views/LegalDetail";
import LawyersDetail from "../views/LawyerDetail";
import LawyersNearBy from "../views/LawyerNearBy.vue";
import News from "../views/News";
import NewDetail from "../views/NewDetail";
import Legals from "../views/Legal";
import Account from "../views/Account";
import Map from "../views/Map";
import Search from "../views/Search";
import about from "../views/About";
import forLaw from "../views/forLawyer";
import Test from "../views/test";
import Terms from "../views/Terms";
import HomeLayout from "../../src/components/layout/Home";
import DefaultLayout from "../../src/components/layout/Default";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        redirect: "/",
        component: HomeLayout,
        children: [{
            path: "/",
            name: "Home",
            component: about,
        }]
    },
    {
        path: "/",
        component: DefaultLayout,
        children: [

            {
                path: "/map",
                name: "Map",
                component: Map,
            },
            {
                path: "/for-law",
                name: "ForLaw",
                component: forLaw,
            },
            {
                path: "/test",
                name: "Test",
                component: Test,
            },
            {
                path: "/terms",
                name: "Terms",
                component: Terms,
            },
            {
                path: "/register",
                name: "Register",
                component: Register,
                meta: { guest: true },
            },
            {
                path: "/login",
                name: "Login",
                component: Login,
                meta: { guest: true },
            },
            {
                path: "/legals",
                name: "Legals",
                component: Legals,
            },
            {
                path: '/legals/:id',
                name: 'LegalDetail',
                component: LegalDetail,
            },
            {
                path: '/profile/:slug',
                name: 'LawyersDetail',
                component: LawyersDetail,
            },
            {
                path: '/lawyers-near-by',
                name: 'LawyersNearBy',
                component: LawyersNearBy,
            },
            {
                path: "/news",
                name: "News",
                component: News,
            },
            {
                path: '/news/:id',
                name: 'NewDetail',
                component: NewDetail,
            },
            {
                path: "/accounts",
                name: "Account",
                component: Account,
                meta: { requiresAuth: true },
            },
            {
                path: '/search',
                name: 'search',
                component: Search,
            },
        ]
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            next();
            return;
        }
        next("/login");
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters.isAuthenticated) {
            next("/accounts");
            return;
        }
        next();
    } else {
        next();
    }
});



export default router;
