<template>
  <div>
    <v-list class="">
      <v-list-item class="align-start">
        <v-list-item-avatar class="align-self-start my-0" size="50">
          <!-- <img v-if="user.avatar" :src="user.avatar.path" /> -->
          <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="" />
        </v-list-item-avatar>
        <v-list-item-content class="py-0">
          <v-list-item-title>
            <!-- {{ user.name }} -->
            ÂN
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ moment(createdAt).startOf("day").fromNow() }}
          </v-list-item-subtitle>
          <v-card-text class="px-0">
            {{ content }}
          </v-card-text>
          <div class="align-center justify-left">
            <v-btn text right small>
              <v-icon left v-if="is_reaction == true" color="red"
                >mdi-heart</v-icon
              >
              <v-icon left v-else>mdi-heart-outline</v-icon>
              {{ num_of_reactions }}
            </v-btn>
            <v-btn
              text
              right
              small
              v-if="activeReply !== _id"
              @click.stop="handleReplyClick(_id)"
              >Trả Lời</v-btn
            >
            <v-btn
              text
              right
              small
              v-if="activeReply === _id"
              @click.stop="handleReplyClick(0)"
              >Đóng</v-btn
            >
            <!-- {{num_of_replies}} -->
          </div>
          <v-card-text class="pa-0" v-if="num_of_replies > 0">
            <reply
              v-for="(replies, index) in replies.results"
              :key="index"
              :post_id="post_id"
              :_id="replies._id"
              :reviewer="replies.reviewer.user"
              :content="replies.content"
              :createdAt="replies.createdAt"
              :num_of_reactions="replies.num_of_reactions"
              :is_reaction="replies.is_reaction"
              :num_of_replies="replies.num_of_replies"
            />
          </v-card-text>
          <v-card-text class="pa-0" v-if="activeReply === _id">
            <v-list class="py-0">
              <v-list-item class="px-0">
                <v-list-item-icon class="mr-2">
                  <img src="../../assets/images/ava-02.png" alt="" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-text-field
                    dense
                    rounded
                    clearable
                    hide-details
                    filled
                    type="text"
                    v-model="message"
                    @keydown.enter="send"
                    :ref="`commentReplyForm_${_id}`"
                    placeholder="Ý kiến của bạn..."
                  />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import reply from "./reply.vue";
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_SOCIAL_API_URL;
export default {
  name: "comment",
  data() {
    return {
      user: "",
      replies: "",
      message: "",
      headers: {
        "Content-Type": "application/json",
        "x-product": "SOCIAL",
        "x-token": "2cf8c166d002440cc17f900c14316e1d72a24e6b",
      },
      activeReply: "",
    };
  },
  components: {
    reply,
  },
  props: {
    post_id: {
      type: String,
      required: true,
    },
    postid: {
      type: Number,
      required: true,
    },
    _id: {
      type: String,
      required: true,
    },
    reviewer: {
      type: Object,
      required: true,
    },
    createdAt: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    num_of_reactions: {
      type: Number,
      required: true,
    },
    is_reaction: {
      type: Boolean,
      required: true,
    },
    num_of_replies: {
      type: Number,
      required: false,
    },
  },
  created: function () {
    // a function to call getposts action
    this.getUser();
    if (this.num_of_replies > 0) {
      this.getReplies();
    }
  },
  computed: {
    ...mapGetters({
      User: "StateUser",
    }),
  },
  methods: {
    handleReplyClick(commentId) {
      this.activeReply = commentId;
      if (commentId !== 0) {
        this.$nextTick(function () {
          this.$refs[`commentReplyForm_${commentId}`].$refs.input.focus();
        });
      }
    },
    getUser() {
      axios
        .get(`/api/v1/users/byID/${this.reviewer.user_id}`)
        .then((response) => {
          this.user = response.data.data;
        });
    },
    getReplies() {
      let config = {
        method: "get",
        url:
          this.url +
          "comments/" +
          this._id +
          "/posts/" +
          this.post_id +
          "/LIKE/replies",
        headers: {
          "Content-Type": "application/json",
          "x-product": "SOCIAL",
          "x-token": "2cf8c166d002440cc17f900c14316e1d72a24e6b",
        },
      };

      axios(config)
        .then((response) => {
          this.replies = response.data.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    send() {
      let data = {
        comment_id: this._id,
        user_id: this.User.id,
        post_id: this.postid,
        content: this.message,
      };
      console.log(data);
      axios
        .post(this.url + "comments/replies", data, {
          headers: this.headers,
        })
        .then(() => {
          this.replying = false;
          this.$refs.message.reset();
          this.getReplies();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
