<template>
<div>
    <form @submit.prevent="submit" class="login">
        <!-- <div class="form-group"> -->
            <v-text-field
            v-model="form.username"
            outlined
            name="username" 
            id="username" 
            placeholder="Email *"
            ></v-text-field>
        <!-- </div> -->
        <!-- <div class="form-group"> -->
            <v-text-field v-model="form.password" 
            outlined
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" 
            :type="show1 ? 'text' : 'password'" 
            @click:append="show1 = !show1"
            name="password" 
            placeholder="Mật khẩu *"></v-text-field>
            <!-- <input type="password" name="password" id="password" placeholder="Mật khẩu *" v-model="form.password"> -->
        <!-- </div> -->
        <div class="form-group" style="margin-top:0">
            <input class="input-checkbox100" id="ckb1" type="checkbox" name="remember-me">
            <label class="label-checkbox100" for="ckb1">
                Nhớ mật khẩu
            </label>
        </div>
        <div class="btn-sign">
            <button class="submit ">Đăng nhập</button>
        </div>
        <a data-toggle="tab" href="#getOTP" aria-expanded="false" role="tab" aria-controls="home" @click="passTab()">
            <p class="forgot-password">Quên mật khẩu ?</p>
        </a>
    </form>
    <p v-if="showError" id="error" style="color:red;font-size:14px">Tên đăng nhập hoặc mật khẩu không đúng</p>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import $ from 'jquery';
export default {
    name: "Auth",
    components: {},
    data() {
        return {
            show1: false,
            form: {
                username: "",
                password: "",
            },
            // rules: {
            //     required: value => !!value || 'Trường này là bắt buộc.',
            //     min: v => v.length >= 8 || 'Mật khẩu phải ít nhất 8 ký tự',
            //     // emailMatch: () => (`The email and password you entered don't match`),
            // },
            showError: false
        };
    },
    methods: {
        ...mapActions(["LogIn", "passTab"]),
        async submit() {
            const User = new FormData();
            User.append("login", this.form.username);
            User.append("password", this.form.password);
            try {
                await this.LogIn(User);
                this.$router.go();
                this.showError = false
            } catch (error) {
                this.showError = true
            }
        },
        passTab() {
            $('a[data-toggle="tab"]').removeClass("active");
            $('#nameTab').html("Quên mật khẩu");
        }
    },
};
</script>
