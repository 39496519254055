<template>
    <MapMarker />   
</template>

<script>
// import {
//     mapGetters,
//     mapActions
// } from "vuex";
import MapMarker from "../components/Map/MapMarker.vue"
export default {
    name: 'Map',
    components: {
        MapMarker
    },
    metaInfo: {
        title: 'Bản đồ luật sư',
    }
    
};
</script>
