import axios from 'axios'

export default axios.create({
    baseURL: 'https://apistaging.luatsu.com/api/v1/law',// api_base_url
    mode: 'no-cors',
    timeout: 20000, // request timeout
    headers: {
        //'Authorization': 'Bearer ' + LocalStorage.getItem('LocalAccessToken')
    }
})
