<template>
<v-container>
    <v-row>
        <v-col cols="4">
            <v-form>
                <v-row>
                    <v-col cols="12">
                        <v-text-field append-icon="mdi-map-marker" type="text" @click:append="locatorButtonPressed" v-model="coordinates" label="Vị trí của bạn"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-select :items="items"></v-select>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <!-- <v-select label="Standard"></v-select> -->
                        <select v-model="radius">
                            <option value="5">5 KM</option>
                            <option value="10">10 KM</option>
                            <option value="15">15 KM</option>
                            <option value="20">20 KM</option>
                        </select>
                    </v-col>
                    <v-col>
                        <v-btn depressed color="primary" @click="findCloseBuyButtonPressed">
                            Find CloseBuy
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <div class="ui segment" style="max-height:500px;overflow:scroll">
                <div class="ui divided items">
                    <div class="item" v-for="place in places" :key="place.id">
                        <div class="content">
                            <div class="header">{{place.name}}</div>
                            <div class="meta">{{place.vicinity}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </v-col>

        <v-col ref="map">
            map
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import axios from "axios";
export default {
    data: () => ({
        items: ['Foo', 'Bar', 'Fizz', 'Buzz'],

        lat: 0,
        lng: 0,
        type: "",
        radius: "",
        places: []
    }),
    computed: {
        coordinates() {
            return `${this.lat}, ${this.lng}`;
        }
    },
    methods: {
        locatorButtonPressed() {
            navigator.geolocation.getCurrentPosition(
                position => {
                    this.lat = position.coords.latitude;
                    this.lng = position.coords.longitude;
                },

            );
        },
        findCloseBuyButtonPressed() {
            const URL = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${this.lat},${this.lng}&type=restaurant&radius=${this.radius*1000}&key=AIzaSyBAW3CEHu5QHCW5SaDlcX4Nra0mE7xS9B4`;
            axios.get(URL).then(response => {
                this.places = response.data.results;
                this.addLocationsToGoogleMaps();
            }).catch(error => {
                console.log(error.message);
            });
        },
        // addLocationsToGoogleMaps() {
        //     var map = new google.maps.Map(this.$refs['map'], {
        //         zoom: 15,
        //         center: new google.maps.LatLng(this.lat, this.lng),
        //         mapTypeId: google.maps.MapTypeId.ROADMAP
        //     });
        //     this.places.forEach((place) => {
        //         const lat = place.geometry.location.lat;
        //         const lng = place.geometry.location.lng;
        //         let marker = new google.maps.Marker({
        //             position: new google.maps.LatLng(lat, lng),
        //             map: map
        //         });
        //     });
        // }
    },
}
</script>
