import Vuex from 'vuex';
import Vue from 'vue';
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import vi from 'vee-validate/dist/locale/vi';
import VeeValidate, { Validator } from 'vee-validate';
import App from './App.vue'
import router from './router';
import store from './store';
import axios from 'axios';
import moment from 'moment';
import "./assets/css/icons.css";
import "./assets/css/font.css";
import "./assets/css/hover.css";
import "./assets/css/animate.css";
import "./assets/css/style.css";
import "./assets/css/reponsive.css";
import "./assets/css/custom.css";
import "./assets/css/style-hanh.css";
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta';
import VueApexCharts from 'vue-apexcharts';
import 'apexcharts';
Vue.component('apexchart', VueApexCharts);
Vue.use(Vuex);
Vue.use(VueMeta);
Vue.use(VeeValidate);
Validator.localize('vi', vi);
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
Vue.component('GmapCluster', GmapCluster);
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyC8CuDONOyJuo0yCVVbeMxfqT9T1CWhK5M",
        libraries: "places"
    }
});
moment.locale('vi');
Vue.prototype.moment = moment;
axios.defaults.withCredentials = true;
// axios.defaults.baseURL = 'https://apistaging.luatsu.com';
axios.defaults.baseURL = process.env.VUE_APP_DEV_API_URL;

axios.interceptors.response.use(undefined, function(error) {
    if (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            store.dispatch("LogOut");
            return router.push("/");
        }
    }
});

Vue.config.productionTip = false;

new Vue({
    store,
    router,
    vuetify,
    render: (h) => h(App)
}).$mount("#app");