<template>
<section class="cutom-banner home-banner ">
    <div class="full-banner">
        <v-row class="d-flex align-items-center" style="align-items: center!important;">
            <v-col cols="12" md="5" style="z-index:2">
                <div class="group-box">
                    <div class="box-text" v-show="elementVisible">
                        <h5><span>LUATSU.COM </span>, LUẬT SƯ SỐ 4.0 !</h5>
                        <h2 >Nhận diện pháp lý <br class="hide">từ khi khởi sự</h2>
                        <p>Hãy làm đúng ngay từ đầu <br class="hide">kết nối luật sư dễ dàng với vài thao tác</p>
                    </div>
                    <div class="map-img" v-show="!elementVisible">
                        <img src="../assets/images/home_map.svg" alt="">
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="7" style="position:relative">
                <div class="robo text-center">
                    <div class="robo-img">
                        <img src="../assets/images/robo.svg" alt="">
                        <div class="robo-img-child">
                            <div class="robo-01"></div>
                            <div class="robo-02"></div>
                            <div class="robo-03"></div>
                        </div>
                    </div>

                </div>
                <div class="hide" style="margin-top:100px"></div>

                <div class="list-lawyer">
                    
                      <a v-for="m in lawyer" v-bind:key="m.id" :href="$router.resolve({name: 'LawyersDetail', params: { slug: m.slug }}).href">
                        <div class="list-lawyer-item">
                            <div class="item-image">
                                <img v-if="m.avatar" :src="m.avatar.path" alt="">
                                <img v-else src="../assets/images/ls-avatar.png" alt="">
                            </div>
                            <div class="item-text">
                                <h5>{{m.name}}</h5>
                                <p v-for="(m, index) in m.groups" :key="index">{{m.name}}</p>
                            </div>
                        </div>
                      </a>
                </div>

            </v-col>
        </v-row>
    </div>
</section>
</template>

<script>
import axios from "axios";
export default {
    // name: 'HelloWorld',
    data() {
        return {
            lawyer: '',
            elementVisible: true,
            search: ''
        };
    },
    created() {
        this.fetchEventsList();
        this.timer = setInterval(
            this.fetchEventsList, 7000);
        setInterval(() => this.elementVisible = false, 8000)
    },
    mounted() {
        document.body.classList.add('body-homepage')
    },
    metaInfo: {
         title: process.env.VUE_APP_TITLE,
    },
    methods: {
        fetchEventsList() {
            // var ab = Math.floor(Math.random() * this.inventory.length);
            // this.a = this.inventory[ab];
            let config = {
                method: 'get',
                url: '/v2/users/random',
            };
            axios(config)
                .then((response) => {
                    return this.lawyer = response.data;
                })
        },
        onSearch() {
            window.location.href = "/search?k=" + this.search;
        }
    }
}
</script>
