<template>
  <div>
    <div class="title-post">
      <img src="../../assets/images/ls-avatar.png" />
      <h4 class="tieu-de">
        <a>{{ news.author.first_name + " " + news.author.last_name }}</a>
      </h4>
      <span class="time">{{ news.created_at }}</span>
    </div>
    <div class="detail-question">
      <h2 class="tittle-ques">
        {{ news.news_title_vi }}
      </h2>
      <p>
        {{ news.news_summary_vi }}
      </p>
      <span class="xemthem-cauhoi"
        ><a :href="'news/' + news.id">Xem thêm</a></span
      >
    </div>
    <div class="anh-bai-viet row">
      <div v-if="news.thumbnails" class="col-md-12">
        <div class="one-img">
          <a href="#">
            <img class="img-post" :src="news.thumbnails.path" />
          </a>
        </div>
      </div>
    </div>
    <div class="tuongtac">
      <button disabled="" class="btn-comment-active">
        <i class="ti-comment"></i>{{ statusInfo.num_of_comments }}
      </button>
      <button disabled="" class="like-nx-active">
        <svg
          v-if="is_reaction"
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="15"
          viewBox="0 0 17 15"
          fill="none"
        >
          <path
            style="fill: red"
            d="M8.5 15C8.23016 15 8.02778 14.933 7.8254 14.7321C7.15079 14.1295 6.47619 13.5938 5.86905 13.058C4.18254 11.5848 2.69841 10.3795 1.61905 9.10714C0.539683 7.83482 0 6.49554 0 5.08929C0 3.68304 0.472222 2.41071 1.34921 1.47321C2.22619 0.535714 3.44048 0 4.72222 0C5.73413 0 6.61111 0.334821 7.42063 0.9375C7.8254 1.27232 8.1627 1.60714 8.5 2.07589C8.8373 1.60714 9.1746 1.27232 9.57936 0.9375C10.3889 0.334821 11.2659 0 12.2778 0C13.5595 0 14.7738 0.535714 15.6508 1.47321C16.5278 2.41071 17 3.68304 17 5.08929C17 6.49554 16.4603 7.83482 15.3135 9.17411C14.3016 10.3795 12.8175 11.6518 11.0635 13.125C10.4563 13.5938 9.78175 14.1964 9.10714 14.7991C8.97222 14.933 8.76984 15 8.5 15ZM4.72222 1.00446C3.71032 1.00446 2.76587 1.40625 2.02381 2.14286C1.34921 2.87946 1.0119 3.95089 1.0119 5.08929C1.0119 6.29464 1.48413 7.36607 2.49603 8.57143C3.44048 9.70982 4.9246 10.9821 6.61111 12.3884C7.21825 12.8571 7.89286 13.4598 8.56746 14.0625C9.24206 13.4598 9.91667 12.9241 10.5238 12.3884C12.2103 10.9821 13.627 9.77679 14.6389 8.57143C15.6508 7.36607 16.123 6.29464 16.123 5.08929C16.123 3.95089 15.7183 2.87946 15.0436 2.14286C14.369 1.40625 13.4246 1.00446 12.3452 1.00446C11.6032 1.00446 10.9286 1.27232 10.254 1.74107C9.71429 2.14286 9.30952 2.67857 9.10714 3.08036C8.90476 3.21429 8.70238 3.34821 8.5 3.34821C8.29762 3.34821 8.09524 3.21429 7.96032 3.01339C7.75794 2.61161 7.35317 2.14286 6.81349 1.67411C6.20635 1.20536 5.53175 1.00446 4.72222 1.00446Z"
            fill="red"
          ></path>
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="15"
          viewBox="0 0 17 15"
          fill="none"
        >
          <path
            d="M8.5 15C8.23016 15 8.02778 14.933 7.8254 14.7321C7.15079 14.1295 6.47619 13.5938 5.86905 13.058C4.18254 11.5848 2.69841 10.3795 1.61905 9.10714C0.539683 7.83482 0 6.49554 0 5.08929C0 3.68304 0.472222 2.41071 1.34921 1.47321C2.22619 0.535714 3.44048 0 4.72222 0C5.73413 0 6.61111 0.334821 7.42063 0.9375C7.8254 1.27232 8.1627 1.60714 8.5 2.07589C8.8373 1.60714 9.1746 1.27232 9.57936 0.9375C10.3889 0.334821 11.2659 0 12.2778 0C13.5595 0 14.7738 0.535714 15.6508 1.47321C16.5278 2.41071 17 3.68304 17 5.08929C17 6.49554 16.4603 7.83482 15.3135 9.17411C14.3016 10.3795 12.8175 11.6518 11.0635 13.125C10.4563 13.5938 9.78175 14.1964 9.10714 14.7991C8.97222 14.933 8.76984 15 8.5 15ZM4.72222 1.00446C3.71032 1.00446 2.76587 1.40625 2.02381 2.14286C1.34921 2.87946 1.0119 3.95089 1.0119 5.08929C1.0119 6.29464 1.48413 7.36607 2.49603 8.57143C3.44048 9.70982 4.9246 10.9821 6.61111 12.3884C7.21825 12.8571 7.89286 13.4598 8.56746 14.0625C9.24206 13.4598 9.91667 12.9241 10.5238 12.3884C12.2103 10.9821 13.627 9.77679 14.6389 8.57143C15.6508 7.36607 16.123 6.29464 16.123 5.08929C16.123 3.95089 15.7183 2.87946 15.0436 2.14286C14.369 1.40625 13.4246 1.00446 12.3452 1.00446C11.6032 1.00446 10.9286 1.27232 10.254 1.74107C9.71429 2.14286 9.30952 2.67857 9.10714 3.08036C8.90476 3.21429 8.70238 3.34821 8.5 3.34821C8.29762 3.34821 8.09524 3.21429 7.96032 3.01339C7.75794 2.61161 7.35317 2.14286 6.81349 1.67411C6.20635 1.20536 5.53175 1.00446 4.72222 1.00446Z"
            fill="#8E8E8E"
          ></path>
        </svg>
        {{ statusInfo.num_of_reactions }}
      </button>
      <button disabled="" class="btn-share-active">
        <i class="ti-share"></i>{{ statusInfo.num_of_sharings }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      statusInfo: "",
      is_reaction: false,
      headers: {
        "Content-Type": "application/json",
        "x-product": "SOCIAL",
        "x-token": "2cf8c166d002440cc17f900c14316e1d72a24e6b",
      },
      url: process.env.VUE_APP_SOCIAL_API_URL,
    };
  },
  props: ["news"],
  created: function () {
    this.getstatusInfo();
  },
  methods: {
    getstatusInfo() {
      // let urlParam = this.url + 'posts/62217b0d5d805304e9d431ff/LIKE'
      // if (this.auth) {
      let urlParam =
        process.env.VUE_APP_SOCIAL_API_URL +
        "/posts/62217b0d5d805304e9d431ff/users/621754801b8c9485626124b9/LIKE";
      //}
      axios
        .get(`${urlParam}`, {
          headers: this.headers,
        })
        .then((response) => {
          this.statusInfo = response.data.data.results;
          this.is_reaction = response.data.data.results.is_reaction;
        });
    },
  },
};
</script>
