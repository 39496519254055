<template>
    <div class="login-page" style="height:auto">
        <div class="tab-login">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item active">
                    <a class="nav-link" id="nameTab" data-toggle="tab" href="#dangnhap" aria-expanded="false">Đăng nhập</a>
                </li>
            </ul>
            
            <div class="tab-content">
                <div id="dangnhap" class="tab-pane active">
                    <Login />
                </div>
                <div id="getOTP" class="tab-pane">
                    <SendOTP />
                </div>
                <div id="forgot" class="tab-pane">
                    <ForgotPassword />
                </div>
            </div>
        </div>
    </div>
</template>

<script >
    import Login from "@/components/Auth/Login.vue";
    import ForgotPassword from "@/components/Auth/ForgotPassword.vue";
    import SendOTP from "@/components/Auth/SendOTP.vue";
    export default {
        name: 'Auth',
        components:{
            Login,
            ForgotPassword,
            SendOTP
        }
    }
</script>