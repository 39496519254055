<template>
<!-- ======= Main profile ====== -->
<section class="main-profile">
    <div class="head-profile">
        <div class="container">
            <div class="banner-profile">
                <img src="../assets/images/banner-profile.svg">
            </div>
            <div class="infor-head">
                <div class="contact-left">
                    <div class="ava">
                        <img v-if="Lawyers.avatar" :src="Lawyers.avatar.path">
                        <img v-else src="../assets/images/ls-avatar.png">
                    </div>
                    <div class="detail-contact">
                        <p class="postion-name"><span v-for="g in Lawyers.groups" :key="g.id">{{g.name}}</span></p>
                        <div class="name-ls my-tooltip bottom">
                            <p class="name">{{Lawyers.name}}</p>
                            <div class="my-tooltip bottom">
                                <img class="name-tag-ls" src="../assets/images/huy-hieu-ls.svg">
                                <div class="tooltip ">
                                    <p>Luật sư năng nổ</p>
                                </div>
                            </div>
                        </div>
                        <div class="mail">
                            <img src="../assets/images/mail.svg">
                            <span>{{Lawyers.email}}</span>
                        </div>
                        <div class="fb">
                            <img src="../assets/images/fb.svg">
                            <span>/{{Lawyers.facebook.split('/')[3]}}</span>
                        </div>
                        <!-- <div class="follow">
                            <img src="../assets/images/follow.svg">
                            <span>5k nguời theo dõi</span>
                        </div> -->
                        <div class="btn-fl-share">
                            <!-- <div class="btn-follow">
                                <a href="#" class="button btn-fl">
                                    <img src="../assets/images/add-fl.svg">Theo dõi </a>
                                <a href="#" class="button btn-da-fl">
                                    <img src="../assets/images/icon-da-fl.svg">Đã theo dõi </a>
                            </div> -->
                            <a href="#" class="button btn-share">
                                <img src="../assets/images/share.svg">Chia sẻ </a>
                        </div>
                    </div>
                </div>
                <div class="contact-right">
                    <div class="button btn-appointment" data-toggle="modal" data-target="#modal-dat-lich">
                        <i class="ti-calendar"></i>Đặt lịch hẹn
                    </div>
                    <div href="#" class="button btn-call-ls">
                        <a class="hide-phone" v-if="!show_phone" @click="show_phone = !show_phone">
                            <i class="icon-phone"></i>
                            <span>Bấm để hiện số</span>
                        </a>
                        <a class="hide-phone" v-else @click="show_phone = !show_phone">
                            <i class="icon-phone"></i> <span> {{Lawyers.telephone}}</span></a>
                    </div>
                    <div href="#" class="button btn-messager-ls">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                            <g clip-path="url(#clip0_1052_10749)">
                                <path d="M0 10.5951C0 11.0029 0.488571 11.2341 0.835714 11.0029L3.735 9.04342C3.97929 8.87912 4.26214 8.79393 4.56429 8.79393H10.9414C12.0664 8.79393 12.9793 7.92983 12.9793 6.86492V0.530236C12.9793 0.256402 12.7414 0.03125 12.4521 0.03125H2.03786C0.912857 0.03125 0 0.895347 0 1.96026V10.5951ZM1.05429 1.96026C1.05429 1.4491 1.49143 1.02922 2.03786 1.02922H11.9186V6.86492C11.9186 7.37608 11.4814 7.79596 10.935 7.79596H4.56429C4.04357 7.79596 3.54857 7.94809 3.12429 8.23409L1.06071 9.6276V1.96026H1.05429Z" fill="#E66B4B" />
                                <path d="M18.0002 5.83135C18.0002 4.76644 17.0873 3.90234 15.9623 3.90234H14.5545C14.2652 3.90234 14.0273 4.1275 14.0273 4.40133C14.0273 4.67516 14.2652 4.90032 14.5545 4.90032H15.9623C16.5023 4.90032 16.9459 5.31411 16.9459 5.83135V13.4987L14.8823 12.1052C14.4581 11.8192 13.9631 11.667 13.4423 11.667H7.0652C6.5252 11.667 6.08163 11.2533 6.08163 10.736V10.2918C6.08163 10.018 5.84377 9.79281 5.55449 9.79281C5.2652 9.79281 5.02734 10.018 5.02734 10.2918V10.736C5.02734 11.8009 5.9402 12.665 7.0652 12.665H13.4423C13.7381 12.665 14.0273 12.7502 14.2716 12.9145L17.1709 14.8739C17.5181 15.1052 18.0066 14.8739 18.0066 14.4662V5.83135H18.0002Z" fill="#E66B4B" />
                                <path d="M3.79277 3.74992H9.88705C10.1763 3.74992 10.4142 3.52477 10.4142 3.25094C10.4142 2.97711 10.1763 2.75195 9.88705 2.75195H3.79277C3.50348 2.75195 3.26562 2.97711 3.26562 3.25094C3.26562 3.52477 3.50348 3.74992 3.79277 3.74992Z" fill="#E66B4B" />
                                <path d="M3.79277 6.08H9.88705C10.1763 6.08 10.4142 5.85485 10.4142 5.58102C10.4142 5.30718 10.1763 5.08203 9.88705 5.08203H3.79277C3.50348 5.08203 3.26562 5.30718 3.26562 5.58102C3.26562 5.85485 3.50348 6.08 3.79277 6.08Z" fill="#E66B4B" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1052_10749">
                                    <rect width="18" height="15" fill="white" />
                                </clipPath>
                            </defs>
                        </svg> Gửi tin nhắn
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-profile">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <div class="box-profile-ls chart-ls" v-if="Lawyers.speciality.length">
                        <p class="titlte-left-profile">Lĩnh vực hoạt động</p>
                        <apexchart v-if="chart" type="radar" height="250" :options=options :series=series>
                        </apexchart>
                    </div>
                    <div class="box-profile-ls" v-if="Lawyers.reward.length">
                        <p class="titlte-left-profile">Giải thưởng</p>
                        <div class="giai-thuong">
                            <div class="item-gt" v-for="(r, index) in Lawyers.reward" :key="index">
                                <div class="thumnails">
                                    <a href="#">
                                        <div class="icon-box">
                                            <img :src="`${apiUrl}/storage/app/media/${r.reward_img}`">
                                        </div>
                                    </a>
                                </div>
                                <div class="box-wrapper">
                                    <a href="#">{{r.name}}</a>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="box-profile-ls links-ls" style="display:none">
                        <p class="titlte-left-profile">Liên kết luật sư</p>
                    </div>
                    <div class="box-profile-ls ls-tuong-tac" style="display:none">
                        <p class="titlte-left-profile">Tương tác gần đây</p>
                        <ul class="list-question">
                            <li>
                                <img src="../assets/images/icon-question.svg">
                                <a href="FAQs.html"> Thủ tục đổi từ số CMND sang căn cước công dân 2022 <span>
                                        <img src="../assets/images/cmt.svg"> 23 </span>
                                </a>
                            </li>
                            <li>
                                <img src="../assets/images/icon-question.svg">
                                <a href="FAQs.html"> Luật đất đai sửa đổi 2021 như thế nào ? <span>
                                        <img src="../assets/images/cmt.svg"> 55 </span>
                                </a>
                            </li>
                            <li>
                                <img src="../assets/images/icon-question.svg">
                                <a href="FAQs.html"> Chuyển nhượng tài sản cho con nuôi có được không? <span>
                                        <img src="../assets/images/cmt.svg"> 287 </span>
                                </a>
                            </li>
                        </ul>
                        <ul class="list-bai-viet-tt">
                            <li>
                                <img class="img-bai-viet" src="../assets/images/bai-viet01.svg">
                                <a href="detail-news.html"> Ngành luật thường đề cao những luật sư tận tâm <span>
                                        <img src="../assets/images/cmt.svg"> 12 </span>
                                </a>
                            </li>
                            <li>
                                <img class="img-bai-viet" src="../assets/images/bai-viet-02.svg">
                                <a href="detail-news.html"> Một luật sư giỏi đến từ một văn phòng uy tín <span>
                                        <img src="../assets/images/cmt.svg"> 12 </span>
                                </a>
                            </li>
                        </ul>
                        <ul class="list-question">
                            <li>
                                <img src="../assets/images/icon-question.svg">
                                <a href="FAQs.html"> Lấy BHXH cần thủ tục gì? <span>
                                        <img src="../assets/images/cmt.svg"> 23 </span>
                                </a>
                            </li>
                            <li>
                                <img src="../assets/images/icon-question.svg">
                                <a href="FAQs.html"> Đổi họ tên có cần cha mẹ đồng ý hay không? <span>
                                        <img src="../assets/images/cmt.svg"> 55 </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="box-profile-ls">
                        <p class="titlte-left-profile">Bản đồ liên hệ </p>
                        <div class="direct">
                            <img src="../assets/images/chi-duong.svg">
                            <a :href="`https://maps.google.com/?q=${Lawyers.places_lat},${Lawyers.places_lng}`" target="_blank"> Chỉ đường </a>
                        </div>
                        <div class="maps_google">
                            <gmap-map :options="mapOptions" :center="{lat:parseFloat(Lawyers.places_lat), lng:parseFloat(Lawyers.places_lng)}" :zoom="16" map-type-id="terrain" style="width: 100%; height: 250px">
                                <gmap-marker :position="{lat:parseFloat(Lawyers.places_lat), lng:parseFloat(Lawyers.places_lng)}" :clickable="true" />
                            </gmap-map>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="box-profile-ls">
                        <p class="titlte-right-profile">Giới thiệu</p>
                        <div class="border-bottm">
                            <div class="col-info">
                                <div class="col-info-left">
                                    <div class="info-ls">
                                        <p>
                                            <span>Chứng chỉ hành nghề luật sư :</span>{{Lawyers.num_of_certificates}}
                                        </p>
                                    </div>
                                    <div class="info-ls">
                                        <p>
                                            <span>Nơi công tác : </span>{{Lawyers.company}}
                                        </p>
                                    </div>
                                    <div class="info-ls">
                                        <p>
                                            <span>Khu vực : </span>{{Lawyers.places_city}}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-info-right">
                                    <div class="info-ls">
                                        <p>
                                            <span>Thẻ luật sư :</span>{{Lawyers.card_number}}
                                        </p>
                                    </div>
                                    <div class="info-ls">
                                        <p>
                                            <span>Website :</span><span>{{Lawyers.webpage}}</span>
                                        </p>
                                    </div>
                                    <div class="info-ls">
                                        <p>
                                            <span>Tổng số giờ tư vấn : </span>6257
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="border-bottm main-work">
                            <!-- {{Lawyers}} -->
                            <p class="titlte-left-profile">Chuyên môn công việc :</p>
                            <!-- <ul>
                                <li>Từng làm trợ lý tổng giám đốc, thư ký HĐQT của cty cổ phần diệu liệu TW II.</li>
                                <li>Sau đó có làm phó giám đốc phụ trách chuyên môn của cty luật TNHH MẠNH ĐỨC. </li>
                                <li>Từ giữa năm 2009 đến nay làm giám đốc HÃNG LUẬT ROMA</li>
                            </ul> -->
                            <div v-html="Lawyers.job"></div>
                        </div>
                        <div class="border-bottm">
                            <p class="titlte-left-profile">Phương châm nghề nghiệp : </p>
                            <div class="phuong-cham">
                                <div class="cham-ngon" v-html="Lawyers.about"></div>
                                <!-- <div class="set-img">
                                    <a>
                                        <img src="../assets/images/ls.svg">
                                    </a>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="box-profile-ls box-service" v-if="Lawyers.service.lenght">
                        <p class="titlte-right-profile">Gói dịch vụ</p>
                        <div class="row box-service">
                            <div class="col-md-3" v-for="(s, index) in Lawyers.service" :key="index">
                                <div class="item-service">
                                    <a href="">
                                        <div class="icon-box">
                                            <img :src="apiUrl+'/storage/app/media/'+s.img">
                                        </div>
                                        <h6>{{s.name}}</h6>
                                        <span>{{ formatPrice(s.price) }}</span>
                                        <small>đ</small>
                                    </a>
                                    <div class="time-service">
                                        <img src="../assets/images/time.svg"> {{s.time}}
                                        <div class="my-tooltip bottom">
                                            <img class="detail-time" src="../assets/images/info.svg">
                                            <div class="tooltip ">
                                                <p>Thời lượng giải quyết dự kiến để hoàn thành ( nếu không có yếu tố khách quan khác phát sinh)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <rating-list :lawyer_id="Lawyers.soc_id"></rating-list>
                </div>
            </div>
        </div>
    </div>
    <appointment />

</section>
<!-- ======= End Main profile====== -->
</template>

<style>
.item-gt .icon-box,
.item-service .icon-box {
    position: relative;
    width: 79px;
    padding-top: 79px;
    margin: 0 auto;
}

.item-gt .icon-box {
    width: 70px;
    padding-top: 70px;
}

.item-gt .icon-box img,
.item-service .icon-box img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
}

.main-profile .phuong-cham .cham-ngon {
    width: 100% !important;
}

.main-profile .content-profile .box-profile-ls.chart-ls {
    padding-bottom: 0 !important;
}

.main-profile .infor-head .contact-left .ava {
    margin-top: 25px;
    margin-left: 25px;
    position: relative;
    padding-left: 0 !important;
    padding-top: 175px !important;
    width: 175px;
}

.main-profile .infor-head .contact-left .ava img {
    width: 175px;
    height: 175px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}
</style>

<script>
import RatingList from '../components/Rating/list-rating.vue';
import appointment from '../components/form/appointment.vue';
import axios from "axios";
import {
    mapGetters,
    mapActions
} from "vuex";
export default {
    name: 'GetLegals',
    data: () => ({
        apiUrl: process.env.VUE_APP_DEV_API_URL,
        chart: false,
        mapOptions: {
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            scaleControl: false,
            styles: [{
                    featureType: "poi",
                    stylers: [{
                        visibility: "off"
                    }],
                },
                {
                    featureType: "poi.business",
                    stylers: [{
                        visibility: "off"
                    }],
                },
                {
                    featureType: "transit",
                    elementType: "labels.icon",
                    stylers: [{
                        visibility: "off"
                    }],
                }
            ]
        },
        options: {
            chart: {
                type: 'radar',
                toolbar: {
                    show: false
                },
            },
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '10px'
                },
            },
            plotOptions: {
                radar: {
                    polygons: {
                        strokeColor: '#e8e8e8',
                        fill: {
                            colors: ['#f8f8f8', '#fff']
                        }
                    }
                }
            },

            xaxis: {
                categories: [],
                labels: {
                    show: false
                }
            },
            yaxis: {
                show: false,
                tickAmount: 7,
            },
            markers: {
                size:5,
                hover: {
                    size: 7
                }
            }

        },
        series: [{
            name: 'Thang điểm',
            data: [],
        }],
        show_phone: false
    }),
    components: {
        RatingList,
        appointment
    },
    metaInfo() {
        return {
            title: this.Lawyers.name,
        }
    },
    created: function () {
        this.CurentLawyers(this.$route.params.slug);
        this.getSpec();
    },
    mounted() {
        document.body.classList.add('body-profile');

    },
    computed: {
        ...mapGetters({
            Lawyers: "StateCurentLawyers"
        })

    },
    methods: {
        ...mapActions(["CurentLawyers"]),
        formatPrice(value) {
            let val = (value / 1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        async getSpec() {
            let specID = this.Lawyers.speciality.map(({
                specialities
            }) => specialities);
            let response = await axios.post(`/v2/specialities`, {
                id: specID
            });
            this.options.xaxis.categories = response.data;
            this.series[0].data = this.Lawyers.speciality.map(({
                point
            }) => point);
            this.chart = true;
        },

    }
};
</script>
