<template>
<div class="login-page" style="height:auto; margin-top:100px">
    <div class="tab-login">
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item active">
                <a class="nav-link" data-toggle="tab" href="#dangnhap" aria-expanded="false">Đăng Ký</a>
            </li>
            <!-- <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#dangky" aria-expanded="false">Đăng ký</a>
            </li> -->
        </ul>

        <div class="tab-content">

            <div id="dangky" class="tab-pane active">
                <Register />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Register from "@/components/Auth/Register.vue";
export default {
    name: 'Auth',
    components: {
        Register
    },
    metaInfo: {
        title: "Đăng ký",
    },
}
</script>
