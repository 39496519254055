<template>
<div class="register">
    <div>
        <form novalidate>
            <div v-if="step === 1">

                <v-text-field outlined v-model="form.email" v-validate="'required|email|unique'" :class="{'input': true, 'is-danger': errors.has('Email') }" name="Email" type="email" placeholder="Email *"></v-text-field>

                <v-text-field v-model="form.name" type="text" outlined @click:append="show1 = !show1" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('Họ tên') }" name="Họ tên" placeholder="Họ tên *"></v-text-field>

                <v-text-field outlined v-validate="{ required: true, regex: /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/ }" :class="{'input': true, 'is-danger': errors.has('Số điện thoại') }" type="tel" name="Số điện thoại" placeholder="Số điện thoại *" v-model="form.phone"></v-text-field>

                <v-text-field outlined :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" v-validate="'required|min:8'" :class="{'input': true, 'is-danger': errors.has('Mật khẩu') }" name="Mật khẩu" placeholder="Mật Khẩu *" v-model="form.password" ref="Mật khẩu"></v-text-field>

                <v-text-field outlined :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" :type="show2 ? 'text' : 'password'" @click:append="show2 = !show2" :class="{'input': true, 'is-danger': errors.has('Nhập lại mật khẩu') }" v-validate="'required|confirmed:Mật khẩu'" data-vv-as="Nhập lại" name="Nhập lại mật khẩu" placeholder="Nhập lại mật khẩu *" v-model="form.password_confirmation"></v-text-field>

                <div class="btn-sign">
                    <v-btn color="primary" @click.prevent="next">Đăng ký</v-btn>
                </div>
            </div>

            <div v-if="step === 2">
                <div class="form-group">
                    <p>Điền mã xác thực được gởi tới điện thoại của bạn để kích hoạt tài khoản.</p>
                </div>
                <div class="form-group" style="margin-bottom:0">
                    <v-text-field outlined type="text" name="otp" placeholder="Mã xác thực" v-model="form.otp"></v-text-field>
                </div>
                <div class="btn-sign">
                    <v-btn color="primary" @click.prevent="submit">Đăng ký</v-btn>
                </div>

            </div>
            <ul class="mes">
                <li v-for="error in errors.all()" :key="error">{{ error }}</li>
            </ul>
        </form>

    </div>

</div>
</template>

<style>
.mes {
    list-style: circle;
    list-style-position: inside;
    padding-left: 10px;
    margin-top: 10px;
}

.mes li {
    font-size: 12px;
    color: red;
    animation: .5s fadeIn;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes fadeIn {
    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>

<script>
import axios from "axios";
import {
    Validator
} from "vee-validate";
import {
    mapActions
} from "vuex";
export default {
    name: "",
    components: {},
    data() {
        return {
            show1: false,
            show2: false,
            step: 1,
            form: {
                email: "",
                name: "",
                phone: "",
                otp: "",
                password: "",
                password_confirmation: ""
            },
            showError: false
        };
    },
    methods: {
        ...mapActions(["sendSMS", "verifyOtp", "Register"]),
        next() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    try {
                        const Phone = new FormData();
                        Phone.append("phone", this.form.phone);
                        const sendSMS = this.sendSMS(Phone);
                        if (sendSMS.error == 1) {
                            alert("Không thể gửi tin nhắn")
                        } else {
                            this.step++;
                        }
                    } catch (error) {
                        alert("Không thể gửi tin nhắn")
                    }
                }
            });
        },
        async submit() {
            try {
                let verify = await this.verifyOtp(this.form);
                console.log(verify);
                if (verify.status == 200) {
                    if (verify.data.status == 0) {
                        alert("Mã xác thực không chính xác")
                    } else {
                        let UserForm = new FormData()
                        UserForm.append('email', this.form.email)
                        UserForm.append('name', this.form.name)
                        UserForm.append('telephone', this.form.phone)

                        UserForm.append('password', this.form.password)
                        UserForm.append('password_confirmation', this.form.password_confirmation)
                        let a = await this.Register(UserForm)
                        if (a === undefined) {
                            alert('Đăng ký thất bại');
                            this.step--;
                        } else {
                            alert('Đăng ký thành công');
                            return this.$router.go();
                        }
                    }
                }else{
                    alert('Đăng ký thất bại');
                    this.step--;
                }

            } catch (error) {
                this.showError = true
            }
        },
    },
    mounted() {
        const isUnique = (value) =>
            new Promise((resolve) => {
                setTimeout(() => {
                    axios
                        .get(`/v2/users/email/${value}`)
                        .then(function (response) {
                            let status = response.data.status
                            if (status === 0) {
                                return resolve({
                                    valid: true,
                                });
                            }
                            return resolve({
                                valid: false,
                                data: {
                                    message: `${value} đã được sử dụng.`,
                                },
                            });
                        })
                }, 200);
            });
        Validator.extend("unique", {
            validate: isUnique,
            getMessage: (field, params, data) => data.message,
        });
    },

};
</script>
