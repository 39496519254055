import Comment from '../ReactionRepository';

// const resource = "/questions";

export default {
    get(post_id) {
        return Comment.get(`posts/${post_id}/LIKE/comments`);
    },
    // getByID(id){
    //     return Repository.get(`${resource}/${id}`);
    // }, 
    // getByAuthor(authorID){
    //     return Repository.get(`${resource}/author/${authorID}`);
    // },
    // create(question){
    //     return Repository.create(`${resource}`,question);
    // }
}