<template>
<div>
    <v-list>
        <v-list-item class="align-start pa-0">
            <v-list-item-avatar class="align-self-start my-0" size="50">
                <!-- <img v-if="user.avatar" :src="imageUrl" /> -->
                <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="">
            </v-list-item-avatar>
            <v-list-item-content class="py-0">
                <!-- <v-list-item-title>{{ user.name }}</v-list-item-title> -->
                <v-list-item-title>Min</v-list-item-title>
                <v-list-item-subtitle>
                    {{moment(createdAt).startOf('day').fromNow()}}
                </v-list-item-subtitle>
                <v-card-text class="px-0">
                    {{content}}
                </v-card-text>
                <div class="align-center justify-left">
                    <v-btn text right small>
                        <v-icon left v-if="is_reaction == true" color="red">mdi-heart</v-icon>
                        <v-icon left v-else>mdi-heart-outline</v-icon>
                        {{num_of_reactions}}
                    </v-btn>
                    <v-btn text right small v-if="activeReply !== _id" @click.stop="handleReplyClick(_id)">Trả Lời</v-btn>
                    <v-btn text right small v-if="activeReply === _id" @click.stop="handleReplyClick(0)">Đóng</v-btn>
                </div>
                <v-card-text class="pa-0" v-if="activeReply === _id">
                    <v-list class="py-0">
                        <v-list-item class="px-0">
                            <v-list-item-icon class="mr-2">
                                <img src="../../assets/images/ava-02.png" alt="">
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-text-field dense rounded clearable hide-details filled type="text" v-model="message" @keydown.enter="send" :ref="`commentReplyForm_${_id}`" placeholder="Ý kiến của bạn..." />
                            </v-list-item-content>
                        </v-list-item>

                    </v-list>
                </v-card-text>
                <v-card-text class="pa-0" v-if="num_of_replies > 0">
                    <reply v-for="(replies, index) in replies.results" :key="index" :post_id="post_id" :_id="replies._id" :reviewer="replies.reviewer.user" :content="replies.content" :createdAt="replies.createdAt" :num_of_reactions="replies.num_of_reactions" :is_reaction="replies.is_reaction" :num_of_replies="replies.num_of_replies" />
                </v-card-text>

            </v-list-item-content>
        </v-list-item>
    </v-list>
</div>
</template>

<script>
import axios from "axios";
export default {
    name: 'reply',
    data() {
        return {
            user: '',
            url: process.env.VUE_APP_SOCIAL_API_URL,
            replying: false,
            replies: '',
            message: '',
            activeReply: ''
        }
    },
    props: {
        showComment: Boolean,
        post_id: {
            type: String,
            required: true,
        },
        _id: {
            type: String,
            required: true,
        },
        reviewer: {
            type: Object,
            required: true,
        },
        createdAt: {
            type: String,
            required: true,
        },
        content: {
            type: String,
            required: true,
        },
        num_of_reactions: {
            type: Number,
            required: true,
        },
        is_reaction: {
            type: Boolean,
            required: true
        },
        num_of_replies: {
            type: Number,
            required: false,
        }

    },
    created: function () {
        // a function to call getposts action
        this.getUser();
        if (this.num_of_replies > 0) {
            this.getReplies()
        }
    },
    methods: {
        handleReplyClick(commentId) {
            // Update the active reply
            this.activeReply = commentId;
            // Focus the input
            if (commentId !== 0) {
                this.$nextTick(function () {
                    this.$refs[`commentReplyForm_${commentId}`].$refs.input.focus();
                });
            }
        },
        getUser() {
            axios.get(`/v2/users/socID/${this.reviewer._id}`)
                .then((response) => {
                    this.user = response.data.data;
                })
        },
        getReplies() {
            let config = {
                method: 'get',
                url: this.url + '/comments/' + this._id + '/posts/' + this.post_id + '/LIKE/replies',
                headers: {
                    'Content-Type': 'application/json',
                    'x-product': 'SOCIAL',
                    'x-token': '2cf8c166d002440cc17f900c14316e1d72a24e6b'
                }
            };

            axios(config)
                .then((response) => {
                    this.replies = response.data.data.results;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
}
</script>
