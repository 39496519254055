<template>
<div class="box-content custom-filter">
    <div class="box-filter">
        <a href="#" class="button btn-filter"><img src="../assets/images/filter.svg"> Bộ lọc </a>
        <div class="combo" style="width:200px">
            <v-select v-model="selectedLaw" :items="in_law" item-text="name" label="Chuyên môn" v-on:change="filteredList" multiple dense solo hide-details>
                <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                        <span>{{ item.name }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ selectedLaw.length - 1 }})
                    </span>
                </template>
            </v-select>
        </div>
        <div class="combo">

            <v-select :items="city" item-text="places_city" label="Khu vực" v-model="selectedCity" v-on:change="filteredList" dense solo hide-details></v-select>
        </div>
        <div class="combo">
            <v-select :items="gender" item-text="name" label="Giới tính" v-model="selectedGender" v-on:change="filteredList" dense solo hide-details></v-select>
        </div>
        <div class="combo" style="display: inline-flex; width: 300px;align-items: center;">
            <v-btn icon class="mx-2" dark small @click="locatorButtonPressed" color="red">
                <v-icon dark>
                    mdi-crosshairs-gps
                </v-icon>
            </v-btn>
            <div v-if="isHidden == true" style="display:flex">
                <range-slider class="slider" min="0" max="100" v-model="km" no-calibration no-popover v-on:change="filteredList">
                </range-slider>
                {{ km + 'km' }}
            </div>

        </div>
    </div>
    <div class="main-content">
        <div class="content-left">
            <button class="btn-collapse"><i class="ti-shift-left"></i></button>
            <h1 class="main-title">{{law.length}} kết quả "{{keyworks}}" </h1>
            <div class="tags">
                <a href="#">#saoketaisan</a>
                <a href="#">#honnhangiadinh</a>
                <a href="#">#saoycongchung</a>
            </div>
            <div class="list-ls scrollbar" id="style-1">
                
                <div class="list-box" :key="index" v-for="(m, index) in law">
                    <a href="#" class="ava-ls" @click="openWindow(m, index)">
                        <img v-if="m._source.avatar" :src=" m._source.avatar.path" class="ava">
                        <img v-else @click="openWindow(m, index)" src="../assets/images/ls-avatar.png" alt="">
                    </a>
                    <div class="content-list">
                        <h3 class="name"><a href="#" @click="openWindow(m, index)">{{m._source.name}}</a> </h3>
                        <div class="position-job">
                            <span>Cử nhân luật</span>
                        </div>
                        <div class="address">
                            <img src="../assets/images/map-pin.svg">
                            <span>{{m._source.places_address}}</span>
                        </div>
                        <div class="read-more-question">
                            <!-- <router-link :to="{ name: 'LawyersDetail', params: { slug: m._source.slug }}">Xem thêm</router-link> -->
                            <a :href="$router.resolve({name: 'LawyersDetail', params: { slug: m._source.slug }}).href">Xem thêm</a>
                            <i class="ti-arrow-right ml-1"></i>
                        </div>
                    </div>
                </div>
                <!-- <a href="#" class="button read-more-list">Xem thêm (còn 130 luật sư)</a> -->
            </div>
        </div>
        <div class="content-right">

            <gmap-map id="map" v-bind="options" :options="mapOptions">

                <gmap-marker :position="{
                    lat: parseFloat(this.lat),
                    lng: parseFloat(this.lng)
                    }" />
                    <gmap-cluster>
                <gmap-custom-marker :key="index" v-for="(m, index) in law" :marker="{
                    lat: parseFloat(m._source.places_lat),
                    lng: parseFloat(m._source.places_lng)
                    }" @click.native="openWindow(m, index)">
                    <div class="marker">
                            <img v-if="m._source.avatar" :src="m._source.avatar.path" alt="" >
                            <img v-else src="../assets/images/ls-avatar.png" alt="">
                        </div>
                </gmap-custom-marker>
                <gmap-info-window @closeclick="window_open=false" :opened="window_open" :position="infoPosition" :options="infoOptions">
                    <div class="box-hover-location">
                        <div class="content-location">
                            <div class="ava">
                                <a href="#" class="ava-ls">
                                    <img v-if="infoOptionsContent.avatar" :src=" infoOptionsContent.avatar.path" class="ava">
                                    <img v-else src="../assets/images/ls-avatar.png" alt="">
                                </a>
                            </div>
                            <div class="content-list">
                                <h3 class="name"><a href="#">{{infoOptionsContent.name}}</a> </h3>
                                <div class="raiting">
                                    <i class="icon-star"></i>
                                    <i class="icon-star"></i>
                                    <i class="icon-star"></i>
                                    <i class="icon-star"></i>
                                    <i class="icon-star"></i>
                                    <span> 5.0</span>
                                    <span class="cmt"><a href="#">(34 đánh giá)</a></span>
                                </div>
                                <div class="contact-ls">
                                    <a :href="`mailto:${infoOptionsContent.email}`" class="button"><i class="ti-email"></i> </a>
                                    <a href="#" class="button"><i class="ti-comment-alt"></i> </a>
                                    <a :href="`tel:${infoOptionsContent.telephone}`" class="button"><i class="icon-phone"></i> </a>
                                </div>
                                <div class="address">
                                    <img :src="cityIcon">
                                    <span>{{infoOptionsContent.places_address}}</span>
                                </div>
                                <div class="website-ls">
                                    <img :src="webIcon">
                                    <span>{{infoOptionsContent.webpage}}</span>
                                </div>
                            </div>
                            <div class="more-infor">
                                <span v-if="infoOptionsContent.card_number" class="td">Số thẻ luật sư :</span>
                                <span v-if="infoOptionsContent.card_number">{{infoOptionsContent.card_number}}</span>
                                <span v-if="infoOptionsContent.in_law" class="td">Lĩnh vực :</span>

                                <span class="lv-law"><span :key="index" v-for="(il, index) in infoOptionsContent.in_law">{{il.name}}</span></span>
                                <span v-if="infoOptionsContent.about" class="td">Giới thiệu :</span>
                                <span v-if="infoOptionsContent.about" v-html="infoOptionsContent.about"></span>
                                <div class="read-more">
                                    <!-- <router-link :to="{ name: 'LawyersDetail', params: { slug: infoOptionsContent.slug }}">Xem thêm</router-link> -->
                                    <a :href="$router.resolve({name: 'LawyersDetail', params: { slug: infoOptionsContent.slug }}).href">Xem thêm</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </gmap-info-window>
                </gmap-cluster>
            </gmap-map>
        </div>
    </div>
</div>
</template>

<script>
import RangeSlider from 'v-range-slider';
import GmapCustomMarker from 'vue2-gmap-custom-marker';

import {
    mapGetters,
    mapActions
} from "vuex";
import axios from "axios";
export default {
    name: 'Search',
    data() {
        return {
            isHidden: false,
            lat: '',
            lng: '',
            km: '',
            options: {
                zoom: 5.5,
                center: {
                    lat: 16.8319423,
                    lng: 106.7788635
                },
            },
            mapOptions: {
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                scaleControl: false,
                styles: [{
                        featureType: "poi",
                        stylers: [{
                            visibility: "off"
                        }],
                    },
                    {
                        featureType: "poi.business",
                        stylers: [{
                            visibility: "off"
                        }],
                    },
                    {
                        featureType: "transit",
                        elementType: "labels.icon",
                        stylers: [{
                            visibility: "off"
                        }],
                    }
                ]
            },
            info_marker: null,
            infowindow: {
                lat: 16.8319423,
                lng: 106.7788635
            },
            infoPosition: null,
            infoContent: null,
            infoOptions: {
                pixelOffset: {
                    zoom: 10,
                    width: 0,
                    height: -50
                },
                maxWidth: 400
            },
            window_open: false,
            currentMidx: null,
            selectedGender: '',
            selectedCity: '',
            splitCity: '',
            selectedLaw: [],
            cityIcon: require('../assets/images/map-pin.svg'),
            webIcon: require('../assets/images/web.svg'),
            keyworks: null,
            chuyenMon: ['Cầm cố tài sản', 'Di chúc', 'Hôn nhân - gia đình', 'Sao kê', 'Công chứng', 'Bảo hiểm', 'Hình sự', 'Dân sự'],
            city: [],
            law: '',
            in_law: [],
            infoOptionsContent: '',
            gender: [{
                name: 'Nam',
                value: 'male'
            }, {
                name: 'Nữ',
                value: 'female'
            }, {
                name: 'Không xác định',
                value: 'unknown'
            }],
            cats:[]
        }
    },
    created: function () {
        this.keyworks = this.$route.query.k;
        this.GetLawyers()
        this.filteredList()
        this.getCity()
        this.getLaw()
        this.addCat()

    },
    components: {
        RangeSlider,
        'gmap-custom-marker': GmapCustomMarker
    },
    computed: {
        ...mapGetters({
            Lawyers: "StateLawyers"
        }),
        markers() {
            return this.Lawyers.map(({
                name,
                email,
                telephone,
                places_address,
                places_city,
                places_lat,
                places_lng,
                avatar,
                about,
                gender
            }) => ({
                name,
                email,
                telephone,
                address: places_address,
                city: places_city,
                position: {
                    lat: parseInt(places_lat),
                    lng: parseInt(places_lng),
                },
                avatar,
                icon: '',
                about,
                gender
            }));
        }

    },
    methods: {
        ...mapActions(["GetLawyers"]),
        addCat() {
             if (!this.keyworks) return;
            this.cats.push(this.keyworks);
            // this.searchValue = '';
            this.saveCats();
            
        },
        saveCats() {
            let parsed = JSON.stringify(this.cats);
            localStorage.setItem('searchRecent', parsed);
        },
        getCity() {
            let config = {
                method: 'get',
                url: '/api/v1/users/city',

            };
            axios(config)
                .then((response) => {
                    this.city = response.data;
                })
        },
        getLaw() {
            let config = {
                method: 'get',
                url: '/api/v1/inlaw',
            };
            axios(config)
                .then((response) => {
                    this.in_law = response.data;
                })
        },
        getPosition: function (marker) {
            return {
                lat: parseFloat(marker._source.places_lat),
                lng: parseFloat(marker._source.places_lng)
            }
        },
        openWindow(marker, idx) {
            this.infoPosition = this.getPosition(marker);
            this.infoOptionsContent = marker._source;

            if (this.currentMidx === idx) {
                this.window_open = !this.window_open;

            } else {
                this.window_open = true;
                this.currentMidx = idx;
            }
            this.options = {
                zoom: 15,
                center: this.getPosition(marker)
            }
        },

        filteredList() {
            let config = {
                method: 'get',
                url: '/api/v1/search?keywords=' + this.keyworks + '&inlaw=' + this.selectedLaw + '&city=' + this.selectedCity + '&gender=' + this.selectedGender + '&radius=' + this.km + '&ulat=' + this.lat + '&ulng=' + this.lng
            };
            axios(config)
                .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    return this.law = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async locatorButtonPressed() {
            const position = await this.llAddress();
            this.lat = position.coords.latitude;
            this.lng = position.coords.longitude;
            this.options = {
                zoom: 12,
                center: {
                    lat: this.lat,
                    lng: this.lng
                },
            };
            this.isHidden = true;
            return this.filteredList();
        },
        llAddress() {
            return new Promise(function (resolve, reject) {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });
        }
    }
}
</script>

<style scoped>
@import '~v-range-slider/dist/v-range-slider.css';

#map {
    margin-top: 80px;
    margin-bottom: 75px;
    height: calc(100% - 155px);
    width: 100%;
}

.combo::after {
    display: none;
}
footer{
    position: fixed!important;
}
</style>
