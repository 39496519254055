<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle>
          <p class="mb-0" v-if="comments.totalComment > 0">
            {{ comments.totalComment }} bình luận
          </p>
          <p class="mb-0" v-else>Không có bình luận nào</p>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <comment
      v-for="(comment, index) in comments.results"
      :key="index"
      :post_id="post_id"
      :postid="postid"
      :_id="comment._id"
      :reviewer="comment.reviewer.user"
      :content="comment.content"
      :createdAt="comment.createdAt"
      :num_of_reactions="comment.num_of_reactions"
      :is_reaction="comment.is_reaction"
      :num_of_replies="comment.num_of_replies"
    />
    <v-divider class="my-0" />
    <v-card-text class="py-0">
      <v-list class="py-0">
        <v-list-item class="px-0">
          <v-list-item-avatar class="mr-2">
            <v-img src="../../assets/images/ls-avatar.png"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-text-field
              ref="message"
              dense
              rounded
              clearable
              hide-details
              filled
              type="text"
              v-model="message"
              @keydown.enter="send"
              placeholder="Ý kiến của bạn..."
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </div>
</template>
<script>
import axios from "axios";
import comment from "./comment.vue";
export default {
  data() {
    return {
      message: "",
      comments: "",
      // replies: '',
      headers: {
        "Content-Type": "application/json",
        "x-product": "SOCIAL",
        "x-token": "2cf8c166d002440cc17f900c14316e1d72a24e6b",
      },
      url: process.env.VUE_APP_SOCIAL_API_URL,
    };
  },
  components: {
    comment,
  },
  props: {
    post_id: {
      type: String,
      required: true,
    },
    postid: {
      type: Number,
      required: true,
    },
    user_id: {
      type: Number,
      required: false,
    },
  },
  created: function () {
    this.getComment();
  },
  methods: {
    send() {
      // alert(this.message);
      if (this.user_id) {
        let data = {
          user_id: this.user_id,
          post_id: this.postid,
          content: this.message,
        };
        axios
          .post(this.url + "/comments", data, {
            headers: this.headers,
          })
          .then(() => {
            this.getstatusInfo();
          })
          .catch((error) => {
            console.log(error);
          });
        this.$refs.message.reset();
        this.getComment();
        return;
      }
      this.$router.push("/login");
    },
    getComment() {
      axios
        .get(
          process.env.VUE_APP_SOCIAL_API_URL +
            "/posts/" +
            this.post_id +
            "/LIKE/comments",
          {
            headers: this.headers,
          }
        )
        .then((response) => {
          this.comments = response.data.data.results;
          // this.replyCount = response.data.data.results.num_of_replies;
          // if(this.replyCount = 0){
          //     let commentID = this.comments.results._id
          //     axios.get(this.url + 'comments' + commentID + '/posts/' + this._id + '/LIKE/replies', {headers: this.headers})
          //     .then((response) => {
          //         this.replies = response.data.data.results;
          //     })
          // }
        });
    },
  },
};
</script>
