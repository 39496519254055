import Repository from '../Repository';

const resource = "/news";

export default {
    get(perPage,page) {
        return Repository.get(`${resource}?perPage=${perPage}&page=${page}`);
    },
    getByID(id){
        return Repository.get(`${resource}/${id}`);
    }, 
    getByAuthor(authorID){
        return Repository.get(`${resource}/author/${authorID}`);
    },
}