<template>
<div class="main-tt">
    <!-- Section 1 -->
    <section>
        <div class="container">
            <div class="row ">
                <!--columns tin noi bat-->
                <div class="blogs">
                    <div class="breadcrumbs">
                        <router-link :to="{ name: 'Home'}">
                            <i class="icon_house_alt"></i>
                        </router-link>
                        <i class=" arrow_carrot-right"></i>
                        <router-link :to="{ name: 'News'}">
                            Tin tức
                        </router-link>
                        <i class=" arrow_carrot-right"></i>
                        <a href="#">Hôn nhân - gia đình</a>
                        <i class=" arrow_carrot-right"></i>
                        <a href="#">{{ New.news_title_vi}}</a>
                    </div>

                </div>
            </div>
        </div>
    </section>
    <!-- Section2 -->
    <section>
        <div class="container">
            <div class="row padding-section3 chi-tiet-tt">
                <div class="col-md-4">
                    <div class="danh-muc-tin">
                        <div class="noi-dung">
                            <h1> {{ New.news_title_vi }}</h1>
                            <span class="time">20/10/2021</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <figure>
                        <img :src="New.thumbnails.path">
                    </figure>

                </div>
            </div>
        </div>
    </section>
    <!-- Section3 -->
    <section>
        <div class="container">
            <div class="row noi-dung-tt">

                <div v-html="New.content_html"></div>
                <!-- Binh Luan -->
                <div v-for="tag in New.tags" :key="tag" class="tags-tt">
                    <span>Thẻ :</span>
                    <a href="#">{{tag.keywords}}</a>
                </div>
                <div class="binh-luan-tt">
                    <div class="form-group">
                        <label class="tieu-de-bl">Bình luận</label>
                        <textarea name="" class="form-control" placeholder="Viết nhận xét của bạn ở đây"></textarea>
                    </div>
                    <list-comment
                  v-if="New._id"
                  v-show="showComment"
                  :post_id="New._id"
                  :postid="New.id"
                  :user_id="13"
                ></list-comment>
                
                </div>

            </div>
        </div>
    </section>
    <!--Tin tuc lien quan-->
    <section style="margin-bottom: 10%;">
        <div class="container tt-lien-quan">
            <div class="row d-m-text-gray">
                <div class="col-md-12">
                    <h2>Các bài viết của {{New.author.first_name +" " + New.author.last_name}}</h2>
                </div>
                <div class="col-md-4" style="border: 1px solid black">
                    <div class="danh-muc-tin">
                        <div class="noi-dung">
                            <h1> <a href="chi-tiet-tin-tuc.html">Phó thủ tướng : 'Quy hoạch lại các nhiệm vụ và viện nghiên cứu'</a></h1>
                            <span class="time">1 giờ trước</span>
                        </div>
                    </div>
                    <div class="tuongtac">
                  <button
                    class="btn-comment-active"
                    style="margin-right:10%"
                  >
                    <i class="ti-comment"></i>75
                  </button>
                  <button disabled class="like-nx-active" >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="15"
                      viewBox="0 0 17 15"
                      fill="none"
                    >
                      <path
                        d="M8.5 15C8.23016 15 8.02778 14.933 7.8254 14.7321C7.15079 14.1295 6.47619 13.5938 5.86905 13.058C4.18254 11.5848 2.69841 10.3795 1.61905 9.10714C0.539683 7.83482 0 6.49554 0 5.08929C0 3.68304 0.472222 2.41071 1.34921 1.47321C2.22619 0.535714 3.44048 0 4.72222 0C5.73413 0 6.61111 0.334821 7.42063 0.9375C7.8254 1.27232 8.1627 1.60714 8.5 2.07589C8.8373 1.60714 9.1746 1.27232 9.57936 0.9375C10.3889 0.334821 11.2659 0 12.2778 0C13.5595 0 14.7738 0.535714 15.6508 1.47321C16.5278 2.41071 17 3.68304 17 5.08929C17 6.49554 16.4603 7.83482 15.3135 9.17411C14.3016 10.3795 12.8175 11.6518 11.0635 13.125C10.4563 13.5938 9.78175 14.1964 9.10714 14.7991C8.97222 14.933 8.76984 15 8.5 15ZM4.72222 1.00446C3.71032 1.00446 2.76587 1.40625 2.02381 2.14286C1.34921 2.87946 1.0119 3.95089 1.0119 5.08929C1.0119 6.29464 1.48413 7.36607 2.49603 8.57143C3.44048 9.70982 4.9246 10.9821 6.61111 12.3884C7.21825 12.8571 7.89286 13.4598 8.56746 14.0625C9.24206 13.4598 9.91667 12.9241 10.5238 12.3884C12.2103 10.9821 13.627 9.77679 14.6389 8.57143C15.6508 7.36607 16.123 6.29464 16.123 5.08929C16.123 3.95089 15.7183 2.87946 15.0436 2.14286C14.369 1.40625 13.4246 1.00446 12.3452 1.00446C11.6032 1.00446 10.9286 1.27232 10.254 1.74107C9.71429 2.14286 9.30952 2.67857 9.10714 3.08036C8.90476 3.21429 8.70238 3.34821 8.5 3.34821C8.29762 3.34821 8.09524 3.21429 7.96032 3.01339C7.75794 2.61161 7.35317 2.14286 6.81349 1.67411C6.20635 1.20536 5.53175 1.00446 4.72222 1.00446Z"
                        fill="#8E8E8E"
                      ></path>
                    </svg>
                    15
                  </button>
                  <button disabled class="btn-share-active">
                    <i class="ti-share"></i>10
                  </button>
                </div>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
import {
    mapGetters,
    mapActions
} from "vuex";
import listComment from "../components/comments/list-comment.vue";
export default {
    name: 'NewDetail',
    components: {
        listComment,
    },
    created: function () {
        this.CurentNewDetail(this.$route.params.id)
    },
    metaInfo() {
        return {
            title: this.New.news_title_vi,
            meta: [
                {
                    property: 'og:description',
                    content: this.New.summary
                },
                {
                    property: 'og:image',
                    content: this.New.thumbnails.path
                }
            ]
        }
    },
    computed: {
        ...mapGetters({
            New: "StateNewDetail"
        })
    },
    methods: {
        ...mapActions(["CurentNewDetail"])
    }
};
</script>
