<template>
<div class="nhan-xet">
    <!-- {{users_id}} -->
    <p class="titlte-right-profile">Nhận xét từ khách hàng & đồng nghiệp</p>
    <div class="box-profile-ls">
        <div class="col-info">
            <div class="tong-nx">
                <div class="box-nx">
                    <div v-if="rating_detail.rating_total > 0">
                        <div class="total-point">{{rating_detail.rating_point_total}}</div>
                        <div class="total-star">
                            <v-rating color="#59A3EF" :value="rating_detail.rating_point_total" background-color="#ebebeb" class="pa-0" medium readonly length="5" halfIcon="mdi-star-half" half-increments></v-rating>
                        </div>
                        <div class="total-review">({{rating_detail.rating_total}} đánh giá)</div>
                    </div>
                    <div v-else>
                        <div class="total-point">5</div>
                        <div class="total-star">
                            <v-rating color="#59A3EF" :value="5" background-color="#ebebeb" class="pa-0" medium readonly length="5" halfIcon="mdi-star-half" half-increments></v-rating>
                        </div>
                        <div class="total-review">({{rating_detail.rating_total}} đánh giá)</div>
                    </div>
                </div>
                <div class="">
                    <div class="middle" v-for="(r, index) in rating_count" :key="index">
                        <span>{{r.point}}.0</span>
                        <div class="bar-container">
                            <v-progress-linear :value="r.total/rating_detail.rating_total*100" background-color="#ebebeb" color="#59A3EF"></v-progress-linear>
                        </div>
                    </div>
                </div>
            </div>
            <div href="#" class="button btn-viet-dg" data-toggle="modal" data-target="#modal-viet-danh-gia">Viết đánh giá</div>
        </div>
    </div>
    <rating :login_user="User" v-for="(r, index) in rating" :key="index" :data="r" />
    <!-- <div class="xemthem-nx">
        <button type="button" class="">Xem thêm 32 đánh giá</button>
    </div> -->
    <div class="modal fade" id="modal-viet-danh-gia" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content" v-if="User">
                <div class="modal-header">
                    <h5 class="modal-title">Nhận xét & đánh giá</h5>
                    <button class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="tags-ls">
                        <div class="total-star">
                            <v-rating color="#59A3EF" v-model="ratings.rating_point" background-color="#ebebeb" class="pa-0" medium length="5" halfIcon="mdi-star-half" half-increments hover></v-rating>
                        </div>
                        <div class="tags">
                            <v-select v-model="ratings.tags" :items="tags" label="Thẻ" :menu-props="{ bottom: true, offsetY: true }" hint="Chọn thẻ" multiple solo hide-details>
                                <template v-slot:selection="{ item, index }">
                                    <v-chip v-if="index === 0">
                                        <span>{{ item }}</span>
                                    </v-chip>
                                    <span v-if="index === 1" class="grey--text text-caption">
                                        (+{{ ratings.tags.length - 1 }} khác)
                                    </span>
                                </template>
                            </v-select>
                        </div>
                    </div>
                    <textarea v-model="ratings.content" placeholder="Nội dung đánh giá..."></textarea>
                    <button type="submit"  class="btn btn-guidanhgia" data-dismiss="modal" aria-label="Close" @click="sendRating">Gửi</button>
                </div>
            </div>
            <div class="modal-content" v-else>
                <div class="modal-header">
                    <h5 class="modal-title">Thông báo</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>Vui lòng đăng nhập để gửi đánh giá!!!</p>
                    <button @click="login" data-dismiss="modal" aria-label="Close" class="btn btn-guidanhgia">Đăng nhập</button>
                </div>
            </div>
        </div>
    </div>
    <v-snackbar v-model="snackbar" :timeout="timeout">
        <template v-slot:action="{ attrs }">
            <v-btn color="success" text v-bind="attrs" @click="snackbar = false">
                Close
            </v-btn>
        </template>
    </v-snackbar>
</div>
</template>

<style>
.main-profile .nhan-xet .tags-ls {
    align-items: center;
}

.main-profile .nhan-xet .tags-ls .total-star {
    margin-bottom: -5px;
}

.main-profile .nhan-xet .modal .tags-ls .tags {
    width: 270px;
}
</style>

<script>
import Rating from './Rating.vue';
import R from './rating'
import {
    mapGetters
} from "vuex";
export default {
    data() {
        return {
            message: '',
            rating: [],
            rating_detail: '',
            rating_count: [{
                    "point": 1,
                    "total": 0
                },
                {
                    "point": 2,
                    "total": 0
                },
                {
                    "point": 3,
                    "total": 0
                },
                {
                    "point": 4,
                    "total": 0
                },
                {
                    "point": 5,
                    "total": 0
                }
            ],
            login_id: '',
            snackbar: false,
            text: 'Gửi thành công.',
            timeout: 2000,
            tags: ['Luật sư thân thiện', 'Luật sư tận tâm', 'Luật sư uy tín'],
            ratings: {
                rating_user: this.lawyer_id,
                raters: '',
                rating_point: 5,
                content: '',
                tags: []
            }
        }
    },
    components: {
        Rating
    },
    props: ['lawyer_id'],
    computed: {
        ...mapGetters({
            User: "StateUser"
        })
    },
    created: function () {
        this.getRating()
        this.getRatingDetail()
    },
    methods: {
        async getRating() {
            try {
                if (this.User) {
                    this.login_id = `?current_user_id=${this.User.soc_id}`
                }
                let r = await R.returnRating(this.lawyer_id, this.login_id);
                this.rating = r.data.data.results.ratings;
            } catch (error) {
                alert(error);
            }
        },
        async getRatingDetail() {
            try {
                let r = await R.returnRatingDetail(this.lawyer_id);
                this.rating_detail = r.data.data.results;
                this.rating_count = this.rating_count.map(c => this.rating_detail.ratings.find(d => d.point === c.point) || c);
            } catch (error) {
                alert(error);
            }
        },
        async sendRating() {
            try {
                if (this.User) {
                    this.ratings.raters = this.User.soc_id;
                }
                await R.sendRating(this.ratings);
                setTimeout(function () {
                    this.getRating();
                    this.getRatingDetail();
                }.bind(this), 2000);

            } catch (error) {
                alert(error);
            }
        },
        login() {
            this.$router.push('/login')
        }
    }
}
</script>
