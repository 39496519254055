import axios from 'axios'
export default axios.create({
    baseURL: process.env.VUE_APP_SOCIAL_API_URL, //process.env.API, // api_base_url
    mode: 'no-cors',
    timeout: 20000, // request timeout
    headers: {
        "Content-Type": "application/json",
        "x-product": "SOCIAL",
        "x-token": "2cf8c166d002440cc17f900c14316e1d72a24e6b",
    }
})