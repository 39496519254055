<template>
    <div>
        <form @submit.prevent="submit" class="forgot">
            <div class="form-group" style="position: relative;">
                <input type="text" name="password" id="password" placeholder="password" v-model="form.password">
            </div>
            <div class="form-group" style="position: relative;">
                <input type="text" name="password-repeat" id="password-repeat" placeholder="password-repeat" v-model="form.password_repeat">
            </div>
            <div class="btn-sign">
                <button class="submit">Thay mật khẩu</button>
            </div>
        </form>
        <p v-if="showError" id="error" style="color:red;font-size:14px">Số mật khẩu không trùng</p>
    </div>
</template>
<style scoped>
.timeout {
    position: absolute;
    top: 25%;
    right: 5%;
    color: #777;
    display: none;
}
</style>
<script>
import { mapActions } from "vuex";
export default {
    name: "Auth",
    components: {},
    data() {
        return {            
            form: {
                phone: ""                
            },
            showError: false
        };
    },
    methods: {
        ...mapActions(["sendSMS"]),
        async submit() {
            const Form = new FormData();
            Form.append("phone", this.form.phone);            
            try {
                let ajax = await this.sendSMS(Form);
                console.log(ajax.data);                
                this.showError = false
            } catch (error) {
                this.showError = true
            }
        },
    },
};
</script>