<template>
<div class="box-content">
    <div class="main-content">
        <div class="content-left">
            <button class="btn-collapse"><i class="ti-shift-left"></i></button>
            <h1 class="main-title">Luật sư nổi bật</h1>
            <div class="tags">
                <a href="#">#saoketaisan</a>
                <a href="#">#honnhangiadinh</a>
                <a href="#">#saoycongchung</a>
            </div>
            <div class="list-ls scrollbar" id="style-1">
                <div class="list-box" :key="index" v-for="(m, index) in filteredLaw" :position="m.position" :icon="m.icon" :clickable="true" :label="m.email">
                    <a href="#" class="ava-ls">
                        <img v-if="m.avatar" :src=" m.avatar.path" class="ava" @click="openWindow(m, index)">
                        <img v-else @click="openWindow(m, index)" src="../../assets/images/ls-avatar.png" alt="">
                        <span class="online"></span>
                    </a>
                    <div class="content-list">
                        <h3 class="name btn-show-profile">
                           <a href="#" @click="openWindow(m, index)">{{m.name}}</a> 
                        </h3>
                        <div class="position-job">
                            <span>Cử nhân luật</span>
                        </div>
                        <div class="address">
                            <img class="mr-1" src="../../assets/images/map-pin.svg">
                            <span>{{m.address.substring(0,60)+".."}}</span>
                        </div>
                        <div class="read-more-question">
                            <!-- <router-link :to="{ name: 'LawyersDetail', params: { slug: m.slug }}">Xem thêm</router-link> -->
                            <a :href="$router.resolve({name: 'LawyersDetail', params: { slug: m.slug }}).href">Xem thêm</a>
                            <i class="ti-arrow-right ml-1"></i>
                        </div>
                    </div>

                </div>
                <!-- <a href="#" class="button read-more-list">Xem thêm (còn 130 luật sư)</a> -->
            </div>
        </div>
        <div class="content-right">
            <gmap-map id="map" v-bind="options" :options="mapOptions">
                <gmap-cluster>
                    <gmap-custom-marker :key="index" v-for="(m, index) in filteredLaw" :marker="m.position" :clickable="true" @click.native="openWindow(m, index)">
                        <div class="marker">
                            <img v-if="m.avatar" :src="m.avatar.path" alt="">
                            <img v-else src="../../assets/images/ls-avatar.png" class="ava">
                        </div>
                    </gmap-custom-marker>
                    <gmap-info-window @closeclick="window_open=false" :opened="window_open" :position="infoPosition" :options="infoOptions">
                        <!-- {{infoOptionsContent}} -->
                        <div class="box-hover-location">
                            <div class="content-location">
                                <div class="ava">
                                    <a href="#" class="ava-ls">
                                        <img v-if="infoOptionsContent.avatar" :src=" infoOptionsContent.avatar.path" class="ava">
                                        <img v-else src="../../assets/images/ls-avatar.png" class="ava">
                                    </a>
                                </div>
                                <div class="content-list">
                                    <h3 class="name"><a href="#">{{infoOptionsContent.name}}</a> </h3>
                                    <div class="raiting">
                                        <i class="icon-star"></i>
                                        <i class="icon-star"></i>
                                        <i class="icon-star"></i>
                                        <i class="icon-star"></i>
                                        <i class="icon-star"></i>
                                        <span> 5.0</span>
                                        <span class="cmt"><a href="#">(34 đánh giá)</a></span>
                                    </div>
                                    <div class="contact-ls">
                                        <a v-if="infoOptionsContent.email" :href="`mailto:${infoOptionsContent.email}`" class="button"><i class="ti-email"></i> </a>
                                        <a href="#" class="button"><i class="ti-comment-alt"></i> </a>
                                        <a v-if="infoOptionsContent.telephone" :href="`tel:${infoOptionsContent.telephone}`" class="button"><i class="icon-phone"></i> </a>
                                    </div>
                                    <div class="address">
                                        <img :src="cityIcon" class="mr-1">
                                        <span>{{infoOptionsContent.address}}</span>
                                    </div>
                                    <div class="website-ls">
                                        <img :src="webIcon" class="mr-1">
                                        <span>{{infoOptionsContent.webpage}}</span>
                                    </div>
                                </div>
                                <div class="more-infor">
                                    <span v-if="infoOptionsContent.card_number" class="td">Số thẻ luật sư :</span>
                                    <span v-if="infoOptionsContent.card_number">{{infoOptionsContent.card_number}}</span>
                                    <span v-if="infoOptionsContent.in_law" class="td">Lĩnh vực :</span>

                                    <span class="lv-law"><span :key="index" v-for="(il, index) in infoOptionsContent.in_law">{{il.name}}</span></span>
                                    <span v-if="infoOptionsContent.about" class="td">Giới thiệu :</span>
                                    <span v-if="infoOptionsContent.about" v-html="infoOptionsContent.about"></span>
                                    <div class="read-more">
                                        <!-- <router-link :to="{ name: 'LawyersDetail', params: { slug: infoOptionsContent.slug }}"></router-link> -->
                                        <a :href="$router.resolve({name: 'LawyersDetail', params: { slug: infoOptionsContent.slug }}).href">Xem thêm</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </gmap-info-window>
                </gmap-cluster>
            </gmap-map>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapGetters,
    mapActions
} from "vuex";
import GmapCustomMarker from 'vue2-gmap-custom-marker';
export default {
    name: "GoogleMap",
    data() {
        return {
            options: {
                zoom: 6,
                center: {
                    lat: 16.8319423,
                    lng: 106.7788635
                }
            },
            mapOptions: {
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                scaleControl: false,
                styles: [{
                        featureType: "poi",
                        stylers: [{
                            visibility: "off"
                        }],
                    },
                    {
                        featureType: "poi.business",
                        stylers: [{
                            visibility: "off"
                        }],
                    },
                    {
                        featureType: "transit",
                        elementType: "labels.icon",
                        stylers: [{
                            visibility: "off"
                        }],
                    }
                ]
            },
            info_marker: null,
            infowindow: {
                lat: 16.8319423,
                lng: 106.7788635
            },
            infoPosition: null,
            infoContent: null,
            infoOptions: {
                pixelOffset: {
                    zoom: 10,
                    width: 0,
                    height: -50
                },
                maxWidth: 400
            },
            infoOptionsContent: '',
            window_open: false,
            currentMidx: null,
            selectedGender: 'all',
            selectedCity: 'all',
            cityIcon: require('../../assets/images/map-pin.svg'),
            webIcon: require('../../assets/images/web.svg')
        };
    },
    components: {
        'gmap-custom-marker': GmapCustomMarker
    },
    watch: {
        keywords() {
            this.filterAll();
        },
        category() {
            this.filterAll();
        },
    },
    created: function () {
        this.GetLawyers()
    },
    computed: {
        ...mapGetters({
            Lawyers: "StateLawyers"
        }),
        markers() {
            return this.Lawyers.map(({
                id,
                name,
                email,
                telephone,
                places_address,
                places_city,
                places_lat,
                places_lng,
                avatar,
                about,
                gender,
                num_of_certificates,
                card_number,
                in_law,
                webpage,
                slug

            }) => ({
                id,
                name,
                email,
                telephone,
                address: places_address,
                city: places_city,
                position: {
                    lat: parseFloat(places_lat),
                    lng: parseFloat(places_lng),
                },
                avatar,
                icon: '',
                about,
                gender,
                num_of_certificates,
                card_number,
                in_law,
                webpage, 
                slug
            }));
        },
        city() {
            return this.Lawyers.map(({
                places_city
            }) => ({
                city_name: places_city
            }))
        },
        gender() {
            return this.Lawyers.map(({
                gender
            }) => ({
                gender_name: gender
            }))
        },
        filteredLaw: function () {
            var vm = this;
            var city = vm.selectedCity;
            var gender = vm.selectedGender;

            if (gender === "all" && city === 'all') {
                return this.markers;
            } else {
                return vm.markers.filter(function (person) {
                    return (gender === 'all' || person.gender === gender) && (city === 'all' || person.city === city);

                });
            }
        }
    },
    methods: {
        ...mapActions(["GetLawyers"]),
        getPosition: function (marker) {
            return {
                lat: parseFloat(marker.position.lat),
                lng: parseFloat(marker.position.lng)
            }
        },
        openWindow(marker, idx) {
            this.infoPosition = this.getPosition(marker);
            this.infoOptionsContent = marker;

            if (this.currentMidx === idx) {
                this.window_open = !this.window_open;

            } else {
                this.window_open = true;
                this.currentMidx = idx;
            }
            this.options = {
                zoom: 12,
                center: this.getPosition(marker)
            }
        },

    }
};
</script>

<style scoped>
#map {
    margin-top: 80px;
    margin-bottom: 75px;
    height: calc(100% - 155px);
    width: 100%;
}

footer {
    position: fixed !important;
}
</style>
