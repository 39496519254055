<template>
    <div class="container">
         <div class="row set-seacrh-ls">
            <!--columns left-->
            <LeftSideBar />
            <!--columns center-->
            <div class="col-md-8 center-document">
               <p class="text-search">{{ Legals.total }} kết quả tìm kiếm</p>
               <div class=" list-search" >
                  <div class=" content-srcoll">
                    <div class="row">
                     <div class="filter">
                        <div class="col-md-2">
                           <img src="#">
                           <span class="text-search">Lọc theo:</span>
                        </div>
                        <div class="col-md-2">                                   
                           <span class="text-search">Tình trạng hiệu lực</span>
                           <img src="#">
                        </div>
                        <div class="col-md-2">                                   
                           <span class="text-search">Loại văn bản</span>
                           <img src="#">
                        </div>
                        <div class="col-md-2">                                    
                           <span class="text-search">Cơ quan ban hành</span>
                           <img src="#">
                        </div>
                        <div class="col-md-2">                                    
                           <span class="text-search">Mới ban hành</span>
                           <img src="#">
                        </div>
                     </div>
                    </div>
                  </div>
               </div>
               <div class="bg-white" v-if="Legals">
                  <div class="item-news" v-for="legal in Legals.data" :key="legal.id">
                     <p class="title-law">Văn bản pháp luật</p>
                     <h6>
                        <router-link :to="{ name: 'LegalDetail', params: { id: legal.id }}" class="title-news">
                        {{legal.document_title_vi}}
                        </router-link>
                     </h6>
                     <!-- <div v-html="legal.document_summary_vi" /> -->
                     <div class="author">
                        <img src="#">
                        <div class="detail-authour">
                           <p class="star-rating">
                              <i class="icon_star"></i>
                              <i class="icon_star"></i>
                              <i class="icon_star"></i>
                              <i class="icon_star"></i>
                              <i class="icon_star"></i>
                           </p>
                           <p class="nane-author">Luật sư: {{legal.created_id}}</p>
                        </div>
                     </div>
                     <div class="line-news"></div>
                  </div>
                  <paginate
                    :page-count="2"
                    :click-handler="loadPage"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'">
                  </paginate>
               </div>
            </div>
         </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import LeftSideBar from '../components/LeftSideBar.vue';
import Paginate from 'vuejs-paginate'
export default {
    name: 'Legals',
    components: {
        LeftSideBar,
        Paginate
    },
    metaInfo: {
         title: "Văn bản pháp luật",
    },
    created: function() {
        // a function to call getposts action
        this.GetLegalDocuments({page:1, perPage:2})
    },
    computed: {
        ...mapGetters({ Legals: "StateLegalDocuments" })
    },
    methods: {
        ...mapActions(["GetLegalDocuments"]),
        loadPage: function (pageNum) {
          this.GetLegalDocuments({page:pageNum, perPage:2})
        }
    }
};
</script>